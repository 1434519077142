
import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '../../../../connect';
import { MainHeading, SecondaryHeading } from '@shared/components/typography';
import { Section } from '../../../../shared/components/layout';
import { useRef } from 'react';
import { useTranslator } from '@localizations';
import getCaptchaKey from '@browser/lib/getCaptchaKey';
import injectStyles from '4finance-components-pl';
import Loading from '../../../../shared/components/Loading';
import Reaptcha from 'reaptcha';
import styles from './AwaitingLogin.jss';

type Props = {
  classes: Record<string, any>;
  finalizeRegistration: (...args: Array<any>) => any;
  consentsStatus: boolean;
};

const AwaitingLogin = ({ classes, finalizeRegistration, consentsStatus }: Props): JSX.Element => {
  const { msg } = useTranslator();
  const captcha = useRef(null);
  const onCaptchaRender = () => {
    captcha.current?.execute();
  };

  const onCaptchaVerify = (token: string) => {
    finalizeRegistration(token, consentsStatus);
  };

  return (
    <Section gray>
      <MainHeading>{msg('awaiting_login.heading')}</MainHeading>
      <SecondaryHeading className={classes.secondaryHeading}>{msg('awaiting_login.text')}</SecondaryHeading>
      <Loading className={classes.loader} />
      <div className={classes.recaptchaWrapper}>
        <Reaptcha
          ref={captcha}
          sitekey={getCaptchaKey()}
          onVerify={onCaptchaVerify}
          onRender={onCaptchaRender}
          size="invisible"
          badge="inline"
        />
      </div>
    </Section>
  );
};

AwaitingLogin.styleRoot = 'AwaitingLogin';

export default compose<any>(
  connectActions({
    finalizeRegistration: ['registration', 'finalizeRegistration'],
  }),
  connectState({
    consentsStatus: ['consents', 'status'],
  }),
  connectPrefetch([
    ['consents', 'fetchMarketingConsentsStatus'],
  ]),
  injectStyles(styles),
)(AwaitingLogin);
