export const SET_API_ERROR = 'SET_API_ERROR';
export const DISMISS_ERROR = 'DISMISS_ERROR';

interface ErrorStatusAction {
  type: string;
  payload?: {
    name: string;
    value: string;
  };
}

export const setErrorStatus = (name: string, value: string): ErrorStatusAction => {
  return {
    type: SET_API_ERROR,
    payload: { name, value },
  };
};

export const dismissError = (): ErrorStatusAction => {
  return {
    type: DISMISS_ERROR,
  };
};

