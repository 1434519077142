export default (({ colors }) => ({
  root: {
    color: colors.red,
    backgroundColor: 'white',
    padding: '5px',
    paddingLeft: '25px',
    borderLeft: `4px solid ${colors.red}`,
    fontSize: '14px',
    fontWeight: 500,
  },
}));
