export default ({ colors }) => ({
  root: {
    fontSize: '16px',
    lineHeight: 1,
    fontWeight: '600',
    cursor: 'pointer',
    color: colors.secondary,
    transition: 'opacity 0.1s',
    textAlign: 'left',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    '&:hover': {
      opacity: 0.75,
    },
  },
});
