export default (({
  colors,
}: any): any => ({
  sliderContainer: {
    padding: '16px 24px 0 24px',
    '& label': {
      fontWeight: '600 !important',
    },

    '& .bar-0': {
      backgroundColor: colors.secondaryDarker,
    },
    '& .handle-bars': {
      backgroundColor: colors.secondary,

      '&:active': {
        backgroundColor: colors.primary,
      },
    },
  },
  termContainer: {
    backgroundColor: colors.veryLightGray,
    padding: '24px',
    textAlign: 'right',
    '&.transparent': {
      backgroundColor: 'transparent',
    },
  },
  input: {
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.1) inset',
    fontWeight: 500,
  },
}));
