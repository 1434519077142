import { modalContent } from '@shared/components/Modal/Modal.jss';

export default ({ colors, mediaQueries }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxHeight: '90vh',
    width: '740px',
    maxWidth: '80%',
    borderRadius: '10px',
    fontWeight: 'normal',
    backgroundColor: colors.mediumLightGray,

    '&>span': {
      display: 'none',
    },
    '& button span svg': {
      display: 'block',
    },

    [mediaQueries.breakpointTablet]: {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
    },
  },

  innerWrapper: {
    padding: '10px 25px',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    alignItems: 'flex-start',

    '&::-webkit-scrollbar': {
      width: '7px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '3px',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: colors.secondary,
    },
  },

  modalContentWrapper: {
    ...modalContent,
    alignItems: 'flex-start',
  },

  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  closeButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'all .15s ease-in-out',
    borderRadius: '3px',
    width: '24px',
    height: '24px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      opacity: '0.7',
    },
    '& svg': {
      height: '18px',
      width: '18px',
    },
  },

  title: {
    position: 'relative',
    width: '100%',
    fontSize: '17px',
    textAlign: 'left',
    fontWeight: '500',
    lineHeight: '60px',
    letterSpacing: '0.4px',

    [mediaQueries.breakpointTablet]: {
      fontSize: '17px',
      padding: '0 15px',
      lineHeight: '40px',
    },
  },

  separator: {
    display: 'none',

    [mediaQueries.breakpointTablet]: {
      display: 'block',
      width: '100%',
      height: '1px',
      backgroundColor: colors.lightGrayLine,
    },
  },

  text: {
    fontSize: '14px',
    margin: '10px 0',
  },

  text2: {
    marginBottom: '20px',
  },

  infoHeading: {
    margin: 0,
    textAlign: 'left',
    alignSelf: 'flex-start',
    fontSize: '14px',
    fontWeight: '700',
  },

  formWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    textAlign: 'left',
    margin: '20px 0',

    '& form': {
      width: '100%',
    },

    '& form > div': {
      marginBottom: '12px',

      [mediaQueries.breakpointTablet]: {
        marginBottom: '20px',
      },
    },

    '& form > div > label > span': {
      fontSize: '13px',
      lineHeight: '16px',

      [mediaQueries.breakpointTablet]: {
        fontSize: '12px',
      },
    },

    [mediaQueries.breakpointTablet]: {
      maxWidth: '100%',
      marginTop: '26px',
      padding: '0 15px',
    },
  },

  consentButton: {
    marginBottom: '30px',
    marginTop: '20px',
  },

  markAllButton: {},

  checkbox: {
    maxWidth: '100%',
  },

  error: {
    position: 'absolute',
    width: '100%',
    color: colors.error,
    fontSize: '13px',
    textAlign: 'center',

    [mediaQueries.breakpointTablet]: {
      textAlign: 'left',
    },
  },
});
