export default (({
  colors,
}: any): any => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1001,
    padding: '60px 32px',
    display: 'flex',
    alignItems: 'center',
    '&:not(.open)': {
      display: 'none',
    },
  },
  container: {
    backgroundColor: colors.white,
    padding: '32px',
    maxWidth: '800px',
    margin: '0 auto',
    borderRadius: '4px',
    width: '100%',
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    marginBottom: '24px',
  },
  iframe: {
    width: '100%',
    display: 'block',
    border: 'none',
    marginTop: '24px',
    minHeight: '60vh',

    '& table': {
      width: '100%',
    },
  },
}));
