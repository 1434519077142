import * as R from '@routes/routesList';
import { BodyText, MainHeading } from '@shared/components/typography';
import { useCallback, useEffect, useRef } from 'react';
import Banner from './Banner';
import CloseButton from '@shared/components/CloseButton';
import injectStyles from '4finance-components-pl';
import LoginForm from './LoginForm';
import styles from './LoginModal.jss';
import useContentContext from '@browser/lib/content/useContentContext';

type Props = {
  classes: Record<string, any>;
  onClose: (...args: Array<any>) => any;
  open: boolean;
};

const LoginModal = (props: Props): JSX.Element | null => {
  const {
    classes,
    open,
    onClose,
  } = props;
  const {
    loginModal: content,
    login: translations,
  } = useContentContext();
  const ref = useRef(null);
  const onBackdropClick = useCallback((e: MouseEvent) => {
    if (e.target === ref.current) {
      onClose();
    }
  }, []);

  useEffect(() => {
    const body: HTMLBodyElement = (document.body as any);

    body.style.overflow = open ? 'hidden' : '';
  }, [open]);

  if (!content) {
    // eslint-disable-next-line no-console
    console.warn('Missing content for LoginModal.');

    return null;
  }

  if (!translations) {
    // eslint-disable-next-line no-console
    console.warn('Missing TranslationServer content for LoginModal.');

    return null;
  }

  const {
    banner,
  } = content.fields;
  const {
    title,
    subtitle,
    password_recovery: passwordRecovery,
  } = translations;

  return open ?
    (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div className={classes.outerContainer} onClick={onBackdropClick} ref={ref}>
        <div className={classes.innerContainer}>
          <Banner content={banner} />
          <div className={classes.formContainer}>
            <CloseButton className={classes.close} onClick={onClose} />
            <MainHeading>
              {title}
            </MainHeading>
            <BodyText className={classes.subtitle}>
              {subtitle}
            </BodyText>
            <LoginForm />
            <div className={classes.recovery}>
              <a href={R.PASSWORD_RECOVERY}>
                {passwordRecovery}
              </a>
            </div>
          </div>
        </div>
      </div>
    ) : null;
};

LoginModal.styleRoot = 'LoginModal';
export default injectStyles(styles)(LoginModal);
