import { compose } from 'redux';
import { connectActions, connectPending, connectState } from '@browser/connect';
import { MARKETING_CHANNELS_MODEL } from '@common/lib/constants';
import { PATCH_CLIENT_CONSENTS } from '@common/settings/actions';
import { useTranslator } from '@localizations';
import _ from 'lodash';
import EditableMarketingConsent from '@dynamic/settings/components/EditableMarketingConsent';
import injectStyles, { Button, Heading } from '4finance-components-pl';
import styles from './MarketingConsentsBlock.jss';
import type { ImmutableMap } from '@common/lib/types';

type Consent = {
  type: string;
  status: string;
  grantDate: string | null;
}

type Props = {
  classes: Record<string, string>;
  webapiConsents: ImmutableMap | null | undefined;
  fetchClientConsents: () => any;
  patchClientConsents: (...args: Array<any>) => any;
  pending: boolean;
}

const MarketingConsentsBlock = (props: Props): JSX.Element => {
  const {
    classes,
    webapiConsents,
    fetchClientConsents,
    patchClientConsents,
    pending,
  } = props;
  const {
    msg,
  } = useTranslator();

  const handleMarkAll = async () => {
    const marketingConsents: string[] = Object.values(MARKETING_CHANNELS_MODEL).flat();
    const marketingAcceptedConsentsBody = webapiConsents
      .toJS()
      .reduce((acc: Record<string, boolean>, consent: Consent) => {
        const formattedConsent = `accept${_.upperFirst(_.camelCase(consent.type))}`;

        if (marketingConsents.includes(formattedConsent)) {
          acc[formattedConsent] = true;
        }

        return acc;
      }, {});

    await patchClientConsents(marketingAcceptedConsentsBody);
    fetchClientConsents();
  };

  return (
    <div className={classes.wrapper}>
      <Heading kind="h4" className={classes.heading}>
        {msg('dashboard.settings.consents_block.heading')}
      </Heading>
      <Button kind="simple" disabled={pending} onClick={handleMarkAll} className={classes.markAllButton}>
        {msg('dashboard.settings.consents_block.mark_all')}
      </Button>
      <div className={classes.consentsWrapper}>
        {Object.keys(MARKETING_CHANNELS_MODEL)
          .map((consent, index) => (
            <EditableMarketingConsent consentKey={consent} key={index} />
          ))}
      </div>
    </div>
  );
};

MarketingConsentsBlock.styleRoot = 'MarketingConsentsBlock';
export default compose<any>(
  connectActions({
    patchClientConsents: ['settings', 'patchClientConsents'],
    fetchClientConsents: ['api', 'fetchClientConsents'],
  }),
  connectPending({
    pending: PATCH_CLIENT_CONSENTS,
  }),
  connectState({
    webapiConsents: ['api', 'fetch', 'client', 'consents', 'query', 'data', 'consents'],
  }),
  injectStyles(styles),
)(MarketingConsentsBlock);
