import { useCallback } from 'react';
import Chevron from '../../../svg/uE005-chevron.svg';
import classnames from 'classnames';
import FooterGroupElement from './FooterGroupElement';
import injectStyles from '4finance-components-pl';
import styles from './FooterGroup.jss';
import type { ContentFooterHyperlinkGroupElement } from '../../../lib/content/types';

type Props = {
  classes: Record<string, any>;
  // Own props
  title: string;
  elements: ContentFooterHyperlinkGroupElement[];
  onClick: () => void;
  open: boolean;
};

const FooterGroup = (props: Props): JSX.Element => {
  const {
    title,
    elements,
    classes,
    onClick,
    open,
  } = props;
  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onClick();
    }
  }, []);

  return (
    <div className={classnames(classes.container, {
      open,
    })}
    >
      <div className={classes.title} onClick={onClick} role={'button'} tabIndex={0} onKeyUp={onKeyUp}>
        {title}
        <div className={classes.dropdownIcon}>
          <Chevron />
        </div>
      </div>
      {elements.map((e, index) => <FooterGroupElement {...e.fields} key={index} />)}
    </div>
  );
};

FooterGroup.styleRoot = 'FooterGroup';
export default injectStyles(styles)(FooterGroup);
