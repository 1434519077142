import * as R from '../../browser/routes/routesList';
import { actions as api } from '../api';
import { ClientData } from '@browser/lib/banks/types';
import { CONSENT_PROPERTY_NAMES_BY_COLA_ID, ID_CARD_ERROR_MAPPING } from '../lib/constants';
import { Consents, Params } from '../types';
import { removeCrossCheckError, setCrossCheckEdited } from '@common/registration/actions';
import { replace, RouterAction, push as updatePath } from 'connected-react-router';
import { RootState } from '@browser/configureStoreWithHistory';
import setFormApiError from '../lib/setFormApiError';
import type { ContentConsentStatement } from '../../browser/lib/content/types';
import type { DispatchType, PersistentStore } from '../types';

export const SEND_CLIENT_IDENTITY_DOCUMENT = 'SEND_CLIENT_IDENTITY_DOCUMENT';
export const SEND_CHANGE_REQUEST = 'SEND_CHANGE_REQUEST';
export const SEND_CHANGE_CONFIRM = 'SEND_CHANGE_CONFIRM';
export const SEND_CHANGE_PASSWORD = 'SEND_CHANGE_PASSWORD';
export const SEND_CLIENT_PHONE = 'SEND_CLIENT_PHONE';
export const SEND_CLIENT_EMAIL = 'SEND_CLIENT_EMAIL';
export const SEND_PHONE_CHANGE = 'SEND_PHONE_CHANGE';
export const SEND_CHANGE_ID = 'SEND_CHANGE_ID';
export const UPDATE_CLIENT_DATA = 'UPDATE_CLIENT_DATA';
export const FETCH_CLIENT_DATA = 'FETCH_CLIENT_DATA';
export const PATCH_CLIENT_SETTINGS = 'PATCH_CLIENT_SETTINGS';
export const PATCH_CLIENT_PASSWORD = 'PATCH_CLIENT_PASSWORD';
export const PATCH_CONSENTS = 'PATCH_CONSENTS';
export const SELECT_BANK = 'SELECT_BANK';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SET_SETTINGS_RECAPTCHA_RESPONSE_TOKEN = 'SET_SETTINGS_RECAPTCHA_RESPONSE_TOKEN';
export const SEND_CHANGE_CONSENT = 'SEND_CHANGE_CONSENT';
export const PATCH_CLIENT_CONSENTS = 'PATCH_CLIENT_CONSENTS';

const checkIfIsFromApplication = (route: string) => route.includes('application');

export interface SendChangeRequest {
  type: typeof SEND_CHANGE_REQUEST
  payload: Promise<void>
}
export interface SendChangeConfirm {
  type: typeof SEND_CHANGE_CONFIRM
  payload: Promise<void>
}
export interface SendChangeConsent {
  type: typeof SEND_CHANGE_CONSENT
  payload: Promise<void>
}
export interface SendChangePassword {
  type: typeof SEND_CHANGE_PASSWORD
  payload: Promise<void>
}
export interface SendClientPhone {
  type: typeof SEND_CLIENT_PHONE
  payload: { promise: (persistentStore: PersistentStore) => Promise<void>}
}
export interface SendClientEmail {
  type: typeof SEND_CLIENT_EMAIL
  payload: { promise: (persistentStore: PersistentStore) => Promise<void>}
}
export interface SendPhoneChange {
  type: typeof SEND_PHONE_CHANGE
  payload: void
}
export interface SendChangeId {
  type: typeof SEND_CHANGE_ID
  payload: Promise<void>
}
export interface UpdateClientData {
  type: typeof UPDATE_CLIENT_DATA
  payload: RouterAction
}
export interface FetchClientData {
  type: typeof FETCH_CLIENT_DATA
  payload: (persistentStore: PersistentStore) => Promise<() => { payload: ClientData }>
}
export interface PatchClientSettings {
  type: typeof PATCH_CLIENT_SETTINGS
  meta: { params: Params }
  payload: { promise: (persistentStore: PersistentStore) => Promise<void>}
}
export interface PatchClientPassword {
  type: typeof PATCH_CLIENT_PASSWORD
  payload: { promise: (persistentStore: PersistentStore) => Promise<void>}
}

export interface SendIdentityDocument {
  type: typeof SEND_CLIENT_IDENTITY_DOCUMENT
  payload: { promise: (persistentStore: PersistentStore) => Promise<void>}
}
export interface PatchConsents {
  type: typeof PATCH_CONSENTS
  payload: { promise: (persistentStore: PersistentStore) => Promise<void>}
}
export interface PatchClientConsents {
  type: typeof PATCH_CLIENT_CONSENTS
  payload: { promise: (persistentStore: PersistentStore) => Promise<void>}
}
export interface SelectBank {
  type: typeof SELECT_BANK
  payload: ({ ...args }: ActionArguments) => Promise<void>
}
export interface AddMessage {
  type: typeof ADD_MESSAGE
  message: string
}
export interface RemoveMessage {
  type: typeof REMOVE_MESSAGE
  message: string
}
export interface SetSettingsRecaptchaResponseToken {
  type: typeof SET_SETTINGS_RECAPTCHA_RESPONSE_TOKEN
  payload: string
}

export type SettingsAction = SendChangeRequest
  | SendChangeConfirm
  | SendChangePassword
  | SendChangeConsent
  | SendClientPhone
  | SendClientEmail
  | SendPhoneChange
  | SendChangeId
  | UpdateClientData
  | FetchClientData
  | PatchClientSettings
  | PatchClientPassword
  | PatchConsents
  | SelectBank
  | AddMessage
  | RemoveMessage
  | SetSettingsRecaptchaResponseToken
  | PatchClientConsents

interface ActionArguments {
  dispatch: DispatchType<SettingsAction>,
  getState: () => RootState,
  getApiResponse: (path: string[], options?: {forceFetch: boolean}) => Record<string, any>
}

// TODO: instead of PersistentStore use actual typed RootState with Middleware types plugged in
export type AsyncActionCreator<T extends SettingsAction> = (p: PersistentStore) => T

function fetchClientData(): AsyncActionCreator<FetchClientData> {
  return ({
    dispatch,
  }) => ({
    type: FETCH_CLIENT_DATA,
    payload: dispatch(api.fetchClient()),
  });
}
export function updateClientData(process: string): AsyncActionCreator<UpdateClientData> {
  return ({ dispatch }) => {
    const getPromise = () => {
      dispatch(fetchClientData());

      if (process === 'application') {
        return dispatch(replace(`${R.APPLICATION_CHANGE_DATA}?from_application=1`));
      }

      return dispatch(replace(R.CLIENT_SETTINGS));
    };

    return {
      type: UPDATE_CLIENT_DATA,
      payload: getPromise(),
    };
  };
}
export function patchClientSettings(params: Params, responseToken?: string): AsyncActionCreator<PatchClientSettings> {
  const responseTokenString = responseToken ? `?responseToken=${responseToken}` : '';

  return ({ httpPut }) => ({
    type: PATCH_CLIENT_SETTINGS,
    meta: { params },
    payload: {
      promise: httpPut(
        `/client/settings${responseTokenString}`,
        params,
      ),
    },
  });
}

function patchConsents(payload: Consents): AsyncActionCreator<PatchConsents> {
  return ({ httpPut }) => ({
    type: PATCH_CONSENTS,
    meta: { payload },
    payload: {
      promise: httpPut(
        '/client/consents',
        payload,
      ),
    },
  });
}

function sendChangeRequest(formName: string, formFieldName: string, apiFunction: (...args: Array<any>) => any, redirect: string): AsyncActionCreator<SendChangeRequest> {
  return ({
    dispatch,
    getState,
  }) => {
    const getPromise = async () => {
      const value = getState().onionForm.getIn(['fields', formName, formFieldName, 'value']);
      const {
        payload,
        error,
      } = await dispatch(apiFunction(value));

      if (error) {
        dispatch(setFormApiError(formName, payload));
        throw new Error(`Client change \`${formFieldName}\` request failed.`);
      }

      await dispatch(fetchClientData());
      dispatch(updatePath(redirect));
    };

    return {
      type: SEND_CHANGE_REQUEST,
      payload: getPromise(),
    };
  };
}

function sendChangeConfirm(formFieldName: string, apiFunction: (arg: string, responseToken?: string) => any, redirect: string): AsyncActionCreator<SendChangeConfirm> {
  return ({
    getState,
    dispatch,
  }) => {
    const getPromise = async () => {
      const code = getState().onionForm.getIn(['fields', 'clientSettingsConfirm', formFieldName, 'value']);
      const responseToken = getState().settings.get('recaptchaResponseToken');

      if (typeof responseToken !== 'string') throw new Error('responseToken missing');

      const {
        payload,
        error,
      } = await dispatch(apiFunction(code, responseToken));

      if (error) {
        dispatch(setFormApiError('clientSettingsConfirm', payload, {
          verificationCode: formFieldName,
        }));
        throw new Error(`Client change \`${formFieldName}\` failed.`);
      }

      if (checkIfIsFromApplication(redirect)) {
        const fieldName = formFieldName.includes('phone') ? 'mobilePhone' : 'email';

        await dispatch(setCrossCheckEdited(fieldName));
        await dispatch(removeCrossCheckError(fieldName));
      }

      await dispatch(fetchClientData());
      dispatch(updatePath(redirect));
    };

    return {
      type: SEND_CHANGE_CONFIRM,
      payload: getPromise(),
    };
  };
}

// - - - - - - - - - -
// Change phone number
// - - - - - - - - - -
export function sendClientPhone(params: { mobilePhone: string }, responseToken?: string): AsyncActionCreator<SendClientPhone> {
  const responseTokenString = responseToken ? `?responseToken=${responseToken}` : '';

  return ({ httpPost }) => ({
    type: SEND_CLIENT_PHONE,
    meta: { params },
    payload: {
      promise: httpPost(
        `/client/phone${responseTokenString}`,
        params,
      ),
    },
  });
}

export function sendChangePhoneRequest(formName = 'clientSettings', redirect: string = R.CHANGE_PHONE_CONFIRM): AsyncActionCreator<SendPhoneChange> {
  return ({ getState, dispatch }) => {
    const getPromise = () => {
      const responseToken = getState().settings.get('recaptchaResponseToken');

      if (typeof responseToken !== 'string') throw new Error('responseToken missing');

      if (responseToken) {
        dispatch(sendChangeRequest(formName, 'mobilePhone', mobilePhone => sendClientPhone({
          mobilePhone,
        }, responseToken), redirect));
      }
    };

    return {
      type: SEND_PHONE_CHANGE,
      payload: getPromise(),
    };
  };
}

export function patchClientPhoneConfirmation(params: any, responseToken?: string): AsyncActionCreator<any> {
  const responseTokenString = responseToken ? `?responseToken=${responseToken}` : 'responseTokenNotProvided';

  return ({ httpPut }) => ({
    type: 'PATCH_CLIENT_PHONE_CONFIRMATION',
    meta: { params },
    payload: {
      promise: httpPut(
        `/client/phone-confirmation${responseTokenString}`,
        params,
      ),
    },
  });
}

export function sendChangePhoneConfirm(redirect: string = R.CHANGE_PHONE_SUCCESS): AsyncActionCreator<SendChangeConfirm> {
  return sendChangeConfirm(
    'phoneVerificationCode',
    (code, responseToken) => patchClientPhoneConfirmation({
      verificationCode: code,
    }, responseToken),
    redirect,
  );
}

// - - - - - - - - - -
// Change email
// - - - - - - - - - -
export function sendClientEmail(params: { email: string }, responseToken?: string): AsyncActionCreator<SendClientEmail> {
  const responseTokenString = responseToken ? `?responseToken=${responseToken}` : '';

  return ({ httpPost }) => ({
    type: SEND_CLIENT_EMAIL,
    meta: { params },
    payload: {
      promise: httpPost(
        `/client/email${responseTokenString}`,
        params,
      ),
    },
  });
}

export function sendChangeEmailRequest(formName = 'clientSettings', redirect: string = R.CHANGE_EMAIL_CONFIRM): AsyncActionCreator<SendPhoneChange> {
  return ({ dispatch, getState }) => {
    const getPromise = () => {
      const responseToken = getState().settings.get('recaptchaResponseToken');

      if (typeof responseToken !== 'string') throw new Error('responseToken missing');

      dispatch(sendChangeRequest(formName, 'email', email => sendClientEmail({
        email,
      }, responseToken), redirect));
    };

    return {
      type: SEND_PHONE_CHANGE,
      payload: getPromise(),
    };
  };
}

export function sendChangeEmailConfirm(redirect: string = R.CHANGE_EMAIL_SUCCESS):AsyncActionCreator<SendChangeConfirm> {
  return sendChangeConfirm('emailVerificationCode', code => api.patchClientEmailConfirmation({
    verificationCode: code,
  }), redirect);
}

export function sendChangeAddress(redirect: string = R.CHANGE_ADDRESS_SUCCESS): AsyncActionCreator<SendChangeRequest> {
  return ({
    dispatch,
    getState,
  }) => {
    const state = getState();
    const {
      apartment,
      city,
      house,
      postalCode,
      street,
    } = state.onionForm.getIn(['fields', 'changeAddress']).toJS();
    const responseToken = state.settings.get('recaptchaResponseToken');
    const location4 = apartment.value ? `${street.value} ${house.value}/${apartment.value}` : `${street.value} ${house.value}`;
    const sanitizedPostalCode = postalCode.value[2] === '-' ? postalCode.value : `${postalCode.value.slice(0, 2)}-${postalCode.value.slice(2)}`;
    const declaredAddress: Record<string, any> = {
      fullAddress: street.value,
      location1: city.value,
      location4,
      postalCode: sanitizedPostalCode,
    };

    if (typeof responseToken !== 'string') throw new Error('responseToken missing');

    const getPromise = async () => {
      const {
        payload,
        error,
      } = await dispatch(patchClientSettings({
        declaredAddress,
      }, responseToken));

      if (error) {
        dispatch(setFormApiError('clientSettings', payload));
        throw new Error('Client change address request failed.');
      }

      if (checkIfIsFromApplication(redirect)) {
        dispatch(setCrossCheckEdited('city'));
        dispatch(setCrossCheckEdited('streetAndHouseFlatNumber'));
        dispatch(setCrossCheckEdited('postalCode'));
        dispatch(removeCrossCheckError('city'));
        dispatch(removeCrossCheckError('streetAndHouseFlatNumber'));
        dispatch(removeCrossCheckError('postalCode'));
      }

      await dispatch(fetchClientData());
      dispatch(updatePath(redirect));
    };

    return {
      type: SEND_CHANGE_REQUEST,
      payload: getPromise(),
    };
  };
}

export function sendClientIdentityDocument(params: { documentNumber: string, countryCode: string, type: string }, responseToken?: string): AsyncActionCreator<SendIdentityDocument> {
  const responseTokenString = responseToken ? `?responseToken=${responseToken}` : '';

  return ({ httpPost }) => ({
    type: SEND_CLIENT_IDENTITY_DOCUMENT,
    meta: { params },
    payload: {
      promise: httpPost(
        `/client/identity-document${responseTokenString}`,
        params,
      ),
    },
  });
}

export function sendChangeId(redirect: string = R.CHANGE_ID_SUCCESS): AsyncActionCreator<SendChangeId> {
  return ({
    getState,
    dispatch,
  }) => {
    const getPromise = async () => {
      const state = getState();
      const newId: string = state.onionForm.getIn(['fields', 'changeIdentityCardNumber', 'identityCardNumber', 'value']);
      const responseToken = state.settings.get('recaptchaResponseToken');

      if (typeof responseToken !== 'string') throw new Error('responseToken missing');

      const {
        payload,
        error,
      } = await dispatch(sendClientIdentityDocument({
        documentNumber: newId,
        countryCode: 'PL',
        type: 'ID_CARD',
      }, responseToken));

      if (error) {
        dispatch(setFormApiError('changeIdentityCardNumber', payload, ID_CARD_ERROR_MAPPING));
        throw Error('New identification number submit failed');
      }

      if (checkIfIsFromApplication(redirect)) {
        await dispatch(setCrossCheckEdited('identityDocument'));
        await dispatch(removeCrossCheckError('identityDocument'));
      }

      await dispatch(fetchClientData());
      dispatch(updatePath(redirect));
    };

    return {
      type: SEND_CHANGE_ID,
      payload: getPromise(),
    };
  };
}

export function patchClientPassword(params: { oldPassword: string, newPassword: string, repeatedPassword: string }, responseToken?: string): AsyncActionCreator<PatchClientPassword> {
  const responseTokenString = responseToken ? `?responseToken=${responseToken}` : '';

  return ({ httpPut }) => ({
    type: PATCH_CLIENT_PASSWORD,
    meta: { params },
    payload: {
      promise: httpPut(
        `/client/password${responseTokenString}`,
        params,
      ),
    },
  });
}

export function sendChangePassword(): AsyncActionCreator<SendChangePassword> {
  return ({
    getState,
    dispatch,
  }) => {
    const getPromise = async () => {
      const state = getState();
      const form = state.onionForm.getIn(['fields', 'clientSettingsChangePassword']);
      const responseToken = state.settings.get('recaptchaResponseToken');
      const oldPassword = form.getIn(['oldPassword', 'value']);
      const newPassword = form.getIn(['newPassword', 'value']);
      const repeatedPassword = newPassword;

      if (typeof responseToken !== 'string') throw new Error('responseToken missing');

      const {
        payload,
        error,
      } = await dispatch(patchClientPassword({
        oldPassword,
        newPassword,
        repeatedPassword,
      }, responseToken));

      if (error) {
        dispatch(setFormApiError('clientSettingsChangePassword', payload));
        throw new Error('Client change `password` failed');
      }

      await dispatch(fetchClientData());
      dispatch(updatePath(R.CHANGE_PASSWORD_SUCCESS));
    };

    return {
      type: SEND_CHANGE_PASSWORD,
      payload: getPromise(),
    };
  };
}
export function selectBank(bank: Record<string, any> | null) {
  return {
    type: SELECT_BANK,
    bank,
  };
}
type Consent = ContentConsentStatement | string;
export function sendChangeConsent(consents: Consent | Consent[], value = true): AsyncActionCreator<SendChangeConsent> {
  return ({
    dispatch,
  }) => {
    const getPromise = async () => {
      const data = consents instanceof Array ? consents : [consents];
      const payload: Consents = data.reduce((all, c) => {
        const colaConsentId = typeof c === 'string' ? c : c.fields.colaConsentId;
        const property = CONSENT_PROPERTY_NAMES_BY_COLA_ID[colaConsentId];

        if (!property) {
          throw new Error('Unknown consent ID.');
        }

        return { ...all,
          [property]: value,
        };
      }, {} as Consents);

      await dispatch(patchConsents(payload));
      await dispatch(api.fetchClientConsents());
    };

    return {
      type: SEND_CHANGE_CONSENT,
      payload: getPromise(),
    };
  };
}
export function addMessage(message: string): AddMessage {
  return {
    type: ADD_MESSAGE,
    message,
  };
}
export function removeMessage(message: string): RemoveMessage {
  return {
    type: REMOVE_MESSAGE,
    message,
  };
}

export function setSettingsRecaptchaResponseToken(payload: string): SetSettingsRecaptchaResponseToken {
  return {
    type: SET_SETTINGS_RECAPTCHA_RESPONSE_TOKEN,
    payload,
  };
}

export function patchClientConsents(params: Record<string, boolean>): AsyncActionCreator<PatchClientConsents> {
  return ({ httpPut }) => ({
    type: PATCH_CLIENT_CONSENTS,
    meta: { params },
    payload: {
      promise: httpPut(
        '/client/consents',
        params,
      ),
    },
  });
}
