export default (({
  colors,
  mediaQueries,
}) => ({
  outerContainer: {
    '&.yellow': {
      backgroundColor: colors.primary,
    },
    '&.gray': {
      backgroundColor: colors.lightGray,
    },
    '&.blue': {
      backgroundColor: colors.primary,
    },
  },
  container: {
    paddingTop: '56px',
    paddingBottom: '56px',
    '&.noTopPadding': {
      paddingTop: 0,
    },
    '&.flex': {
      display: 'flex',
    },
    [mediaQueries.breakpointTablet]: {
      paddingTop: '24px',
    },
  },
  centeredInnerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
