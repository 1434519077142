export default ({ colors }) => ({
  link: {
    color: 'inherit !important',
    margin: '0 16px !important',
    textDecoration: 'none',
    fontWeight: 500,
    position: 'relative',
    fontSize: '16px !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-8px',
      left: 0,
      height: '4px',
      width: '100%',
      backgroundColor: colors.primary,
      opacity: 0,
      transition: 'opacity 0.3s',
    },
    '&:hover::before, &.active::before': {
      opacity: 1,
    },
  },
  mobileLink: {
    fontSize: '18px',
    borderTop: `1px solid ${colors.lightBlue}`,
    padding: '20px',
    display: 'block',
    color: `${colors.white} !important`,
    textDecoration: 'none',
    transition: 'background-color 0.3s',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
});
