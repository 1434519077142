
import { BodyText } from '../../../../shared/components/typography';
import { useTranslator } from '../../../../../localizations';
import classnames from 'classnames';
import injectStyles from '4finance-components-pl';
import QA_CLASSES from '../../../../lib/qa';
import styles from './PenaltyInfo.jss';

import { Translated } from '@localizations/lib/msg';

type Props = {
  classes: Record<string, any>;
  // Own props
  amount: string;
  extra: string;
};

const PenaltyInfo = (props: Props): JSX.Element => {
  const {
    classes,
    extra,
    amount,
  } = props;
  const {
    msg,
  } = useTranslator();

  const penaltyInfo: Translated = msg('dashboard.penalty');

  return (
    <BodyText className={classnames(classes.root, QA_CLASSES.PENALTY_BREAKDOWN)}>
      {`${amount} + ${extra} ${penaltyInfo}`}
    </BodyText>
  );
};

PenaltyInfo.styleRoot = 'PenaltyInfo';
export default injectStyles(styles)(PenaltyInfo);
