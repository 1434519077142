import * as React from 'react';
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';
import handleEvent from '../../../lib/handleEvent';
import injectStyles, { FormError, FormHint, IconCheck, IconChevron, Tooltip } from '4finance-components-pl';
import styles from './Checkbox.jss';

type Props = {
  bordered?: boolean;
  bottomMargin?: boolean;
  children?: React.ReactNode | string;
  classes: Record<string, string>;
  className?: string;
  disabled?: boolean;
  error?: string | boolean;
  hint?: React.ReactNode | string;
  label?: React.ReactNode | string;
  name: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (name: string, value: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  tooltip?: React.ReactNode | string;
  topMargin?: boolean;
  value?: boolean;
  isExpandable?: boolean;
  clippingHeight?: number;
  msg: (key: string) => string;
  toggleButtonHandler: (name: string) => void;
  renderExpandButton?: (isClipped: boolean) => React.ReactNode;
  isScrollable?: boolean;
  hasScrollButtons?: boolean;
  hasToggleButton?: boolean;
  toggleButtonTarget?: string;
};

type State = {
  focused: boolean;
  labelHeight: number;
  isLabelOpen: boolean;
  labelRef: React.RefObject<HTMLDivElement>;
  isScrolledToBottom: boolean;
};

class Checkbox extends React.PureComponent<Props, State> {
  static defaultProps: Partial<Props> = {
    bottomMargin: true,
    topMargin: true,
    value: false,
    isExpandable: false,
    clippingHeight: 60,
  };

  // eslint-disable-next-line react/state-in-constructor
  state: State = {
    focused: false,
    labelHeight: 0,
    isLabelOpen: false,
    labelRef: React.createRef(),
    isScrolledToBottom: false,
  };

  static styleRoot = 'Checkbox';

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onBlur, onChange, onFocus, value, name } = this.props;

    handleEvent(name, onFocus, event);
    handleEvent(name, onChange, value ?? false, event);
    handleEvent(name, onBlur, event);
  };

  handleFocus = () => this.setState({ focused: true });
  handleBlur = () => this.setState({ focused: false });

  setHeight = (element: HTMLSpanElement | null) => {
    if (element) {
      this.setState({ labelHeight: element.scrollHeight });
    }
  };

  toggleLabel = () => {
    this.setState((prevState) => ({ isLabelOpen: !prevState.isLabelOpen }));
  };

  handleScroll = (direction?: 'UP' | 'DOWN') => {
    try {
      const scrollBox = this.state.labelRef.current?.childNodes[0] as HTMLElement;

      if (scrollBox) {
        if (direction === 'UP') {
          scrollBox.scrollBy(0, -10);
        } else if (direction === 'DOWN') {
          scrollBox.scrollBy(0, 10);
        } else {
          const isScrolledToBottom = scrollBox.scrollTop >= scrollBox.scrollHeight - scrollBox.offsetHeight;

          if (this.state.isScrolledToBottom !== isScrolledToBottom) {
            this.setState({ isScrolledToBottom });
          }
        }
      }
      // eslint-disable-next-line no-empty
    } catch {}
  };

  clickHandler = () => {
    const { toggleButtonHandler, name } = this.props;

    toggleButtonHandler(name);
  };

  render() {
    const {
      bordered,
      bottomMargin,
      children,
      classes,
      className: classNameProp,
      disabled,
      error,
      hint,
      label,
      name,
      tooltip,
      topMargin,
      value,
      clippingHeight = 60,
      isExpandable,
      renderExpandButton,
      isScrollable,
      hasScrollButtons,
      hasToggleButton,
      msg,
    } = this.props;

    // console.log('Checkbox props=', this.props);

    const { focused, labelHeight, isLabelOpen, labelRef, isScrolledToBottom } = this.state;
    const isClipped = (isExpandable || isScrollable) && labelHeight > clippingHeight && !isLabelOpen;

    return (
      <div className={classnames({
        [classes.container]: true,
        [classes.container__topMargin]: topMargin,
        [classes.container__bottomMargin]: bottomMargin,
        errorMessage: error,
      }, classNameProp)}
      >
        <div className={classes.wrapper}>
          <label htmlFor={name} className={classnames(classes.label, disabled && classes.label__disabled)}>
            <div className={classnames({
              [classes.checkbox]: true,
              [classes.checkbox__bordered]: bordered,
              [classes.checkbox__focused]: focused,
              [classes.checkbox__error]: error,
              [classes.checkbox__checked]: value,
            })}
            >
              {value && <IconCheck className={classes.icon} />}
            </div>
            <input
              checked={value}
              className={classes.input}
              disabled={disabled}
              id={name}
              name={name}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              type="checkbox"
            />
            <div ref={labelRef}>
              <AnimateHeight
                className={classnames({
                  [classes.fade]: isClipped && !isScrolledToBottom,
                  [classes.scrollable]: isScrollable,
                  [classes.noScrollbar]: hasScrollButtons,
                })}
                duration={500}
                height={isClipped ? clippingHeight : 'auto'}
              >
                <span ref={this.setHeight} className={classes.checkboxLabel}>{children || label}</span>
              </AnimateHeight>
              {hasToggleButton && (
              <button type="button" className={classes.channelsToggle} onClick={this.clickHandler}>
                {msg('marketing_channels.toggle')}
              </button>
              )}
            </div>
            <Tooltip tooltip={tooltip} size={18} />
          </label>
        </div>
        <FormError noMargin>{error}</FormError>
        <FormHint>{hint}</FormHint>
      </div>
    );
  }
}

export default injectStyles(styles)(Checkbox);
