
import { Background } from '@shared/components/layout';
import { compose } from 'redux';
import { ReactChildren } from 'react';
import { withImage } from '4finance-helpers-pl';
import classnames from 'classnames';
import injectStyles, { Container } from '4finance-components-pl';
import QA_CLASSES from '@browser/lib/qa';
import styles from './Proposition.jss';
import type { ContentMainpageProposition } from '@browser/lib/content/types';

type Props = {
  classes: Record<string, any>;
  content: ContentMainpageProposition;
  children: ReactChildren;
  getImagePath: (imgPath: string) => string;
};

const Proposition = (props: Props): JSX.Element => {
  const {
    classes,
    content,
    children,
    getImagePath,
  } = props;

  const { headline1 } = content.fields;
  const logo = getImagePath('../../../assets/img/logo.svg');

  return (
    <>
      <Background className={classes.background} breakpoint={'breakpointLaptopSmall'} position={'top center'}>
        <Container className={classes.innerContainer}>
          <div className={classes.textContainer}>
            <div className={classes.headline1}>
              {headline1}
            </div>
            <img className={classes.logo} src={logo} alt="logo" />
          </div>
          <div className={classnames(classes.desktopCalculator, QA_CLASSES.PROPOSITION_CALCULATOR_DESKTOP)}>
            {children}
          </div>
        </Container>
      </Background>
      <div className={classnames(classes.mobileCalculator, QA_CLASSES.PROPOSITION_CALCULATOR_MOBILE)}>
        {children}
      </div>
    </>
  );
};

Proposition.styleRoot = 'Proposition';
export default compose(
  withImage,
  injectStyles(styles),
)(Proposition);
