
import { compose } from 'redux';
import { connectSelectors } from '@browser/connect';
import { createContext, memo, ReactChildren, useCallback, useContext } from 'react';
import { useTranslator } from '@localizations';
import getNextInstallment from './getNextInstallment';
import type { ImmutableMap } from '@common/lib/types';

export type ExtensionFee = {
  newNextInstalmentDueDate: string;
  newLastInstalmentDueDate: string;
  term: number;
  unit: string;
  fee: number;
  interest: number;
  feeWithoutInterest: number;
};
const defaultContext = {
  nextInstallment: {
    amount: '',
    dueDate: '',
  },
  loanNumber: '',
  dueDate: '',
  repayableAmount: '',
  initialCommissionAmount: '',
  interestAmount: '',
  principalAmount: '',
  delayedAmount: '',
  extraPaymentAmount: '',
  payableAmount: '',
  payNowAmount: '',
  schedulePayment: '',
  loanExtensionFees: ([] as ExtensionFee[]),
  isLatePayment: false,
  isDebtCollection: false,
  hasPenalties: false,
  canExtend: false,
};

type ContextType = typeof defaultContext;
const LoanContext = createContext<ContextType>(defaultContext);

export const useLoanContext = (): ContextType => useContext(LoanContext);
type Props = {
  latestLoan: ImmutableMap | null | undefined;
  latestLoanSchedule: ImmutableMap | null | undefined;
  children: ReactChildren;
};

const LoanContextProvider = (props: Props): JSX.Element => {
  const {
    children,
    latestLoan,
    latestLoanSchedule,
  } = props;
  const {
    numberToCurrency,
    formatDate,
  } = useTranslator();
  // eslint-disable-next-line arrow-body-style
  const toCurrency = useCallback((value: number) => {
    return numberToCurrency(value, {
      precision: 2,
    });
  }, [numberToCurrency]);

  if (!latestLoan || !latestLoanSchedule) {
    return (
      <LoanContext.Provider value={defaultContext}>
        {children}
      </LoanContext.Provider>
    );
  }

  const scheduleItems = latestLoanSchedule.get('items');
  const loanNumber = latestLoan.get('loanNumber');
  const dueDate = latestLoan.get('dueDate');
  const initialCommissionAmount = latestLoan.get('initialCommissionAmount');
  const interestAmount = latestLoan.get('interestAmount');
  const principalAmount = latestLoan.get('principalAmount');
  const penaltyAmount = latestLoan.get('penaltyAmount');
  const commissionAmount = latestLoan.get('commissionAmount');
  const payableAmount = latestLoan.get('payableAmount');
  const payNowAmount = latestLoan.get('payNowAmount');
  const schedulePayment = latestLoan.get('schedulePayment');
  const loanExtensionFees = latestLoan.get('loanExtensionFees');
  const nextInstallment = getNextInstallment(latestLoan, latestLoanSchedule);
  const isDebtCollection = latestLoan.get('debtCollectLoanAgreement');
  const hasPenalties = penaltyAmount > 0;
  const isLatePayment = !isDebtCollection && scheduleItems.some(i => i.get('status') === 'DELAYED');
  const canExtend = Boolean(loanExtensionFees?.size);
  const delayedAmount = isLatePayment ? scheduleItems.reduce((all, i) => {
    if (i.get('status') === 'DELAYED') {
      return all + i.get('totalAmount');
    }

    return all;
  }, 0) : 0;
  const value = {
    nextInstallment: {
      dueDate: formatDate(nextInstallment.dueDate),
      amount: toCurrency(nextInstallment.amount),
    },
    loanNumber,
    dueDate: formatDate(dueDate),
    repayableAmount: toCurrency(initialCommissionAmount + interestAmount + principalAmount),
    initialCommissionAmount: toCurrency(initialCommissionAmount),
    interestAmount: toCurrency(interestAmount),
    principalAmount: toCurrency(principalAmount),
    delayedAmount: toCurrency(delayedAmount),
    extraPaymentAmount: toCurrency(penaltyAmount + commissionAmount),
    payableAmount: toCurrency(payableAmount),
    payNowAmount: toCurrency(payNowAmount),
    schedulePayment: toCurrency(schedulePayment),
    loanExtensionFees: canExtend ? loanExtensionFees.toJS().map((f: ExtensionFee) => ({
      newNextInstalmentDueDate: formatDate(f.newNextInstalmentDueDate),
      newLastInstalmentDueDate: formatDate(f.newLastInstalmentDueDate),
      term: f.term,
      unit: f.unit,
      fee: toCurrency(f.fee),
      interest: toCurrency(f.fee),
      feeWithoutInterest: toCurrency(f.feeWithoutInterest),
    })) : [],
    isLatePayment,
    isDebtCollection,
    hasPenalties,
    canExtend,
  };

  return (
    <LoanContext.Provider value={value}>
      {children}
    </LoanContext.Provider>
  );
};

export default memo<Record<string, unknown>>(compose<any>(connectSelectors({
  latestLoan: ['dashboard', 'latestLoan'],
  latestLoanSchedule: ['dashboard', 'latestLoanSchedule'],
}))(LoanContextProvider));
