import { ComponentProvider } from '@browser/lib/contexts/ComponentContext';
import { compose } from 'redux';
import { connectState } from '@browser/connect';
import { ContentContextProvider } from '@browser/lib/content/useContentContext';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';
import { withRouter } from 'react-router';
import allDynamic from '@localizations/partials/allDynamic.json';
import MainLayoutStatic, { propNames as layoutPropNames } from './MainLayoutStatic';
import MarketingChannelsModal from '@dynamic/registration/components/MarketingChannelsModal/MarketingChannelsModal.react';
import PageLoader from '../components/PageLoader';
import useTranslator from '@localizations/useTranslator';
import type { Props as LayoutProps } from './MainLayoutStatic';

type Props = LayoutProps & {
  allDynamic: Record<string, any>;
  children: React.ReactChildren;
  location: Location;
  consentsStatus: boolean;
};

const MainLayoutDynamic = (props: Props) => {
  const {
    children,
    consentsStatus,
    location: {
      pathname,
    },
    ...otherProps
  } = props;
  const {
    msg,
  } = useTranslator();
  const login = msg('sign_in');

  return allDynamic ? (
    <ComponentProvider value={{
      gatsby: false,
      LinkComponent: Link,
    }}
    >
      <ContentContextProvider value={{ ...allDynamic.fields,
        login,
      }}
      >
        <MainLayoutStatic {...pick(otherProps, layoutPropNames)}>
          <PageLoader pathname={pathname} />
          {children}
          {consentsStatus && <MarketingChannelsModal />}
        </MainLayoutStatic>
      </ContentContextProvider>
    </ComponentProvider>
  ) : null;
};

export default compose<any>(withRouter, connectState({
  isLoggedIn: ['authentication', 'isLoggedIn'],
  isAdditionalAmountAvailable: ['api', 'fetch', 'client', 'query', 'data', 'additionalAmount'],
  consentsStatus: ['consents', 'status'],
}))(MainLayoutDynamic);
