import { StylesheetParams } from '@common/types';

export default ({ colors }: StylesheetParams) => ({
  container: {
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${colors.lightGray}`,
    transition: 'opacity 0.3s',
    '&:last-child': {
      borderBottom: 0,
    },
  },
  content: {
    flex: 'auto',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 52px)',
    '& input': {
      width: '100%',
    },
    '& > div': {
      maxWidth: '100%',
    },
    '& form': {
      paddingRight: '24px',
      width: '100%',
      position: 'relative',
      '& > div > div': {
        border: `1px solid ${colors.inputBorder} !important`,
      },
    },
  },
  close: {
    position: 'absolute',
    top: '8px',
    right: '36px',
  },
  title: {
    fontWeight: 500,
    color: colors.mediumGray,
  },
  value: {
    fontSize: '18px',
    fontWeight: 300,
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: colors.mediumDarkGray,
  },
  button: {
    backgroundColor: colors.primary,
    width: '50px',
    flex: '0 0 50px',
    height: '50px',
    border: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2)',
    padding: '3px 0 0 0',
    '& svg': {
      height: '24px',
      width: '24px',
      '& *': {
        fill: colors.white,
      },
    },
    '&::before': {
      content: '""',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      transition: 'opacity 0.3s',
    },
    '&:hover::before': {
      opacity: 1,
    },
  },
  flexFill: {
    flex: 'auto',
  },
});
