import * as actions from './actions';
import { Record } from 'immutable';

const InitialState = Record({
  activeChannelsModal: '',
  formName: 'registration',
  status: false,
});
const initialState = new InitialState();

export default function appReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.SET_ACTIVE_CHANNELS_MODAL:
      return state.set('activeChannelsModal', action.payload);

    case actions.SET_CHANNELS_FORM_NAME:
      return state.set('formName', action.payload);

    case actions.CLEAR_ACTIVE_CHANNELS_MODAL:
      return state.set('activeChannelsModal', '');

    case `${actions.FETCH_MARKETING_CONSENTS_STATUS}_SUCCESS`:
      return state.set('status', action.payload.enable);
  }

  return state;
}
