import color from 'color';

const CHECKBOX_STYLE = {
  display: 'inline-block',
  height: '20px',
  width: '20px',
  cursor: 'pointer',
};

export default ({ colors }) => ({
  container: {
    paddingLeft: '35px',
    minHeight: '24px',

    '&:hover $checkbox': {
      borderBottom: `2px solid ${colors.primary}`,
    },

    '&:hover $checkbox__checked': {
      backgroundColor: colors.primaryDarker,
      borderBottom: `2px solid ${color(colors.primaryDarker).darken(0.2).hex()}`,
    },

    '&:hover $checkbox__error': {
      backgroundColor: colors.white,
    },
  },

  container__topMargin: {
    marginTop: '10px',
  },

  container__bottomMargin: {
    marginBottom: '25px',
  },

  wrapper: {
    display: 'flex',
  },

  label: {
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
  },

  label__disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',

    '& $checkbox, & $input': {
      cursor: 'not-allowed',
    },
  },

  checkboxLabel: {
    display: 'inline-block',
    paddingTop: '2px',
    verticalAlign: 'top',
    fontSize: '14px',
  },

  input: {
    position: 'absolute',
    opacity: 0,
    left: '-9999px',
    ...CHECKBOX_STYLE,
  },

  checkbox__bordered: {
    border: `1px solid ${colors.inputBorder}`,
  },

  checkbox: {
    position: 'absolute',
    left: '-35px',
    borderBottom: `2px solid ${colors.inputBorder}`,
    borderRadius: '2px 2px 0 0',
    backgroundColor: colors.white,
    transition: 'background-color 0.2s, border-color 0.2s',
    textAlign: 'center',
    ...CHECKBOX_STYLE,
  },

  checkbox__focused: {
    borderBottom: `2px solid ${colors.primary}`,
  },

  checkbox__checked: {
    backgroundColor: colors.primary,
    border: colors.primary,
    borderBottom: `2px solid ${colors.primaryDarker}`,
  },

  checkbox__error: {
    border: color(colors.error).lighten(0.6).hex(),
    backgroundColor: color(colors.error).lighten(0.6).hex(),
    borderBottom: `2px solid ${colors.error}`,
  },

  icon: {
    fill: colors.white,
    width: '11px',
    height: '11px',
  },

  fade: {
    maskImage: 'linear-gradient(to bottom, black 40%, transparent 100%)',
  },

  scrollable: {
    overflow: 'scroll !important',
  },

  noScrollbar: {
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  button: {
    width: '100%',
    height: '25px',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, .015)',
    },
    outline: 'none',
  },

  chevron: {
    width: '15px',
    height: '15px',
    transition: 'transform 500ms',
    fill: colors.secondary,
  },

  chevronOpen: {
    transform: 'rotate3d(1, 0, 0, 180deg)',
  },

  scrollButtons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  scrollButton: {
    display: 'flex',
    width: 23,
    height: 23,
    borderRadius: 2,
    backgroundColor: '#77869F',
    color: 'white',
    border: 'none',
    alignItems: 'center',
    outline: 'none',
    cursor: 'pointer',
  },

  scrollChevron: {
    fill: 'white',
    width: '100%',
    height: '100%',
  },

  up: {
    transform: 'rotate3d(1, 0, 0, 180deg)',
  },

  channelsToggle: {
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: '10px',
    fontWeight: '500',
    transition: 'all 0.15s ease-in-out',
    border: 'none',
    outline: 'none',

    '&:hover': {
      opacity: 0.7,
    },
  },
});
