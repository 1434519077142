
import * as R from '@routes/routesList';
import { APPLICATION_CONFIRM } from '@routes/routesList';
import { BodyText } from '@shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectState } from '@browser/connect';
import { connectField } from '4finance-onion-form-pl';
import {
  CONSENT_PROPERTY_NAMES_BY_COLA_ID,
  ConsentName,
  CWA_DECLARATION,
  MARKETING_CHANNELS_MODEL,
} from '@common/lib/constants';
import { isRequired } from '4finance-validation-pl';
import { SetActiveChannelModal } from '@common/consents/actions';
import { translate, useTranslator } from '@localizations';
import { useCallback, useMemo } from 'react';
import { ValuesType } from 'utility-types';
import Button from '@shared/components/Button';
import Check from '@browser/svg/uEA03-check.svg';
import Checkbox from '@dynamic/components/Checkbox';
import classnames from 'classnames';
import config from '4finance-configuration-pl';
import DeclarationCheckbox from '@dynamic/components/DeclarationCheckbox';
import injectStyles from '4finance-components-pl';
import Loading from '@shared/components/Loading';
import QA_CLASSES from '@browser/lib/qa';
import statementGroup from '@dynamic/registration/statementGroup';
import styles from './Consents.jss';
import type { ContentConsentStatement } from '@browser/lib/content/types';

type Props = {
  classes: ReturnType<typeof styles>;
  setMultipleFields: (...args: Array<any>) => any;
  setFieldValue: (form: string, field: string, value: unknown) => any;
  // Own props
  // eslint-disable-next-line react/no-unused-prop-types
  setName: string;
  formName: string;
  hide: string[];
  hideSelectAll?: boolean;
  renderContent?: (colaConsentId: string, text: string) => JSX.Element;
  showLegalText: boolean;
  currentRoute: { pathname: string };
  setActiveMarketingChannelsModal: (type: string) => SetActiveChannelModal;
  setChannelsFormName: (formName: string) => { type: string; payload: string };
};

const Consents = (props: Props): JSX.Element | [] => {
  const {
    classes,
    setMultipleFields,
    setFieldValue,
    formName,
    hide = [],
    hideSelectAll,
    renderContent,
    setName,
    currentRoute,
    showLegalText = true,
  } = props;
  // eslint-disable-next-line import/namespace
  const consents = (statementGroup)[setName as keyof typeof statementGroup];
  const {
    statements,
  } = consents?.fields || {};
  const {
    msg,
  } = useTranslator();

  if (!consents) {
    return [];
  }

  const isMarketingConsent = (consentName: string): boolean => {
    return Object.keys(MARKETING_CHANNELS_MODEL).includes(consentName);
  };

  const setChannelsForMarketingConsent = (params: { name:string, value:boolean }): void => {
    const { name: consentName, value: isSet } = params;

    if (isMarketingConsent(consentName)) {
      const channels: readonly string[] = MARKETING_CHANNELS_MODEL[consentName as ConsentName];

      channels.forEach((channel) => {
        setFieldValue(formName, channel, isSet);
      });
    }
  };

  const selectAllChannelsInAllMarketingConsents = (): void => {
    const marketingConsents = Object.keys(MARKETING_CHANNELS_MODEL).map(name => ({
      name,
      value: true,
    }));

    marketingConsents.forEach(setChannelsForMarketingConsent);
  };

  const fields = useMemo(() => {
    if (!statements) {
      return null;
    }

    return statements.map((s: ContentConsentStatement, index: number) => {
      const {
        colaConsentId,
        text,
        textLong,
        required,
        withChannels,
      } = s.fields;
      const fieldName = CONSENT_PROPERTY_NAMES_BY_COLA_ID[colaConsentId];
      const qaClass = `${QA_CLASSES.PREFIX}${fieldName}`;

      if (!fieldName) {
        throw new Error(`[Consents] Cannot translate Cola Consent ID \`${colaConsentId}\` to property name.`);
      }

      const onToggleModalClick = (modal: string) => {
        const { setActiveMarketingChannelsModal, setChannelsFormName } = props;

        setChannelsFormName(formName);
        setActiveMarketingChannelsModal(modal);
      };

      const isHidden = hide.includes(colaConsentId);
      const validations = required ? [isRequired()] : [];
      const ConnectedField = translate(connectField(fieldName, {
        isExpandable: !!textLong,
        clippingHeight: 40,
        hasToggleButton: withChannels,
        toggleButtonHandler: onToggleModalClick,
      }, validations)(Checkbox));
      const textContent = textLong ? `${text} ${textLong}` : text;

      const clickHandler = (params: { name:string, value:boolean }): void => {
        setChannelsForMarketingConsent(params);
      };

      const field = (
        <ConnectedField key={index} className={qaClass} onChange={clickHandler}>
          {renderContent ? renderContent(colaConsentId, textContent) : (
            <span dangerouslySetInnerHTML={{
              __html: textContent,
            }}
            />
          )}
        </ConnectedField>
      );

      return isHidden ? (
        <div className={classes.hidden} key={index}>
          {field}
        </div>
      ) : field;
    });
  }, [hide, statements, renderContent]);

  const viewIsInAcceptedRoutes = [APPLICATION_CONFIRM, R.REGISTER, R.DASHBOARD].includes(currentRoute?.pathname);
  const shouldShowDeclaration = config.requireCWADeclaration && viewIsInAcceptedRoutes;

  const onSelectAll = useCallback(() => {
    if (!statements || statements.length === 0) {
      return;
    }

    const fieldValues = (statements as ValuesType<typeof statements>[]).reduce((all, s) => ({ ...all,
      [CONSENT_PROPERTY_NAMES_BY_COLA_ID[s.fields.colaConsentId] as string]: true,
    }), {} as {[k: string]: boolean});
    const fieldErrors = Object.keys(fieldValues).reduce((all, s) => ({ ...all,
      [s]: null,
    }), {});

    selectAllChannelsInAllMarketingConsents();

    setMultipleFields(formName, 'value', fieldValues);
    setFieldValue(formName, CWA_DECLARATION.type, CWA_DECLARATION.name);
    setMultipleFields(formName, 'error', fieldErrors);
  }, [formName, statements]);

  return statements ? (
    <>
      {!hideSelectAll && (
        <>
          <BodyText>
            <b>{msg('consents.select')}</b>
          </BodyText>
          <Button secondary className={classnames(classes.selectAll, QA_CLASSES.CHECK_ALL_CONSENTS)} onClick={onSelectAll}>
            <Check />
            {msg('consents.select_all')}
          </Button>
        </>
      )}
      {fields}
      {shouldShowDeclaration ? <DeclarationCheckbox declaration={CWA_DECLARATION} form={formName} /> : ''}
      {showLegalText ? <p className={classes.legalNotice}>{msg('legal_text')}</p> : ''}
    </>
  ) : <Loading />;
};

Consents.styleRoot = 'Consents';
export default compose<any>(connectActions({
  setMultipleFields: ['onionForm', 'setMultipleFields'],
  setFieldValue: ['onionForm', 'setFieldValue'],
  setActiveMarketingChannelsModal: ['consents', 'setActiveMarketingChannelsModal'],
  setChannelsFormName: ['consents', 'setChannelsFormName'],
}),
connectState({
  currentRoute: ['router', 'location'],
}),
injectStyles(styles))(Consents);
