/**
 * Handles event on form fields
 * @param  {string} name                 Element name
 * @param  {object} eventHandler         Handler to process fired event
 * @param  {any} eventOrValue            Value or event if value is not provided
 * @param  {SyntheticEvent} event        React wrapper around browser native events
 * @return {void}
 */
export default function (name, eventHandler, eventOrValue, event = eventOrValue) {
  if (!eventHandler) {
    return;
  }

  const payload = {
    name,
    value: getCurrentValue(eventOrValue, event),
    type: event.target.type,
  };

  eventHandler(payload, event);
}

/**
 * Check if current value was provided for checkbox. If not or the input is not checkbox use a value from an event.
 * @param  {any} value        Value provided for checkbox to be negated
 * @param  {any} event        Field event
 * @return {mixed}            Current field value
 */
function getCurrentValue(value, event) {
  if (event.target.type === 'checkbox' && (value === undefined || typeof value === 'boolean')) {
    return !value;
  }

  return valueTransform(event.target);
}

/**
 * Transforms value based on target element type
 * @param  {object} target Event target
 * @return {mixed}         Transformed value
 */
function valueTransform(target) {
  switch (String(target.type).toLowerCase()) {
    case 'checkbox':
      return target.checked;
    default:
      return target.value;
  }
}
