import { actions as api } from '../../api';
import config from '4finance-configuration-pl';

function formatActionName(path) {
  const rest = path.slice(1)
    .map(pathPart => `${pathPart[0].toUpperCase()}${pathPart.slice(1)}`)
    .join('');

  return `${path[0]}${rest}`;
}

export default function getApiResponse(getState, dispatch) {
  return async (path, {
    dynamicKeys,
    forceFetch,
    actionName,
  } = {}) => {
    const fetchedPath = path.concat(dynamicKeys || 'query', 'fetched');
    const dataPath = path.concat(dynamicKeys || 'query', 'data');
    const state = getState();

    if (config.environment !== 'production') {
      console.log({
        fetchedPath,
        dataPath,
        state,
      });
    }

    return !forceFetch && getState()
      .api
      .getIn(fetchedPath) ? getState()
        .api
        .getIn(dataPath)
        .toJS() : (await dispatch(api[actionName || formatActionName(path)](...(dynamicKeys || [])))).payload;
  };
}
