import Marketing from './Marketing.json';
import marketingChannels from './marketingChannels.json';
import OldCustomer from './OldCustomer.json';
import OldCustomerNewMC from './OldCustomerNewMC.json';
import Registration from './Registration.json';
import RegistrationNewMC from './RegistrationNewMC.json';

export default {
  Marketing,
  OldCustomer,
  OldCustomerNewMC,
  Registration,
  RegistrationNewMC,
  marketingChannels,
};
