export default ({ colors }) => ({
  button: {
    fontSize: '14px',
    color: colors.white,
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
    backgroundColor: colors.primary,
    transition: 'color 0.3s, background-color 0.3s, border-color 0.3s',
    fontWeight: 600,
    padding: '10px',
    marginLeft: '16px',
    lineHeight: 1,
    cursor: 'pointer',
    '&:hover': {
      color: `${colors.white} !important`,
      backgroundColor: `${colors.primary} !important`,
      borderColor: `${colors.primary} !important`,
    },
    '&.yellow': {
      borderColor: colors.primary,
      color: colors.primary,
    },
  },
});
