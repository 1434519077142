import { compose } from 'redux';
import { connectActions, connectState } from '@browser/connect';
import { GRANTED_STATUS, MARKETING_CHANNELS_MODEL } from '@common/lib/constants';
import { SetActiveChannelModal } from '@common/consents/actions';
import { useTranslator } from '@localizations';
import _ from 'lodash';
import cn from 'classnames';
import injectStyles, { Heading, IconCheck, IconCloseThin } from '4finance-components-pl';
import styles from './EditableMarketingConsent.jss';
import type { ImmutableMap } from '@common/lib/types';

type Consent = {
  type: string;
  status: string;
  grantDate: string | null;
}
type Props = {
  consentKey: string;
  classes: Record<string, string>;
  webapiConsents: ImmutableMap | null | undefined;
  setActiveMarketingChannelsModal: (type: string) => SetActiveChannelModal;
  setChannelsFormName: (formName: string) => { type: string; payload: string };
}

const ICONS = {
  accepted: IconCheck,
  partially_accepted: IconCheck,
  rejected: IconCloseThin,
};

const EditableMarketingConsent = (props: Props): JSX.Element => {
  const {
    consentKey,
    classes,
    webapiConsents,
    setActiveMarketingChannelsModal,
    setChannelsFormName,
  } = props;
  const {
    msg,
  } = useTranslator();

  const handleChooseChannelsClick = () => {
    setChannelsFormName('settings');
    setActiveMarketingChannelsModal(consentKey);
  };

  const getConsentStatus = () => {
    const consentsModel = MARKETING_CHANNELS_MODEL[consentKey];

    if (!webapiConsents) {
      return 'rejected';
    }

    const consentsAll: Consent[] = webapiConsents.toJS()
      .map((consent: Consent) => ({
        [`accept${_.upperFirst(_.camelCase(consent.type))}`]: consent.status === GRANTED_STATUS,
      }))
      .filter((consent: Consent) => consentsModel.includes(Object.keys(consent)[0]));

    const allAccepted = consentsAll.every(consent => Object.values(consent)[0]);

    if (allAccepted) {
      return 'accepted';
    }
    const someAccepted = consentsAll.some(consent => Object.values(consent)[0]);

    return someAccepted
      ? 'partially_accepted'
      : 'rejected';
  };

  const consentStatus = getConsentStatus();
  const consentName = _.snakeCase(consentKey);
  const Icon = ICONS[consentStatus];

  return (
    <div className={classes.consent}>
      <div className={classes.headingBar}>
        <div className={cn(classes.tickIconWrapper, classes[consentStatus])}>
          <Icon className={cn(classes.tickIcon)} />
        </div>
        <Heading kind="h5" className={classes.heading}>
          {msg(`dashboard.settings.consents_block.${consentName}_heading`)}
        </Heading>
        {consentStatus !== 'accepted' && (
          <div className={cn(classes.infoTag, classes[consentStatus])}>
            {msg(`dashboard.settings.consents_block.info_tag_${consentStatus}`)}
          </div>
        )}
      </div>
      <p className={classes.text} dangerouslySetInnerHTML={{ __html: msg(`dashboard.settings.consents_block.${consentName}`) }} />
      <button type="button" className={classes.chooseChannelsButton} onClick={handleChooseChannelsClick}>
        {msg('dashboard.settings.consents_block.choose_channels')}
      </button>
    </div>
  );
};

EditableMarketingConsent.styleRoot = 'EditableMarketingConsent';
export default compose<any>(
  connectActions({
    setActiveMarketingChannelsModal: ['consents', 'setActiveMarketingChannelsModal'],
    setChannelsFormName: ['consents', 'setChannelsFormName'],
  }),
  connectState({
    webapiConsents: ['api', 'fetch', 'client', 'consents', 'query', 'data', 'consents'],
  }),
  injectStyles(styles),
)(EditableMarketingConsent);
