export default ({ colors, mediaQueries }) => ({
  root: {
    position: 'relative',
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '120px',
    paddingBottom: '40px',
    [mediaQueries.breakpointLaptopSmall]: {
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  },
  background: {
    [mediaQueries.breakpointLaptopSmall]: {
      height: 'auto !important',
      marginTop: '60px',
    },
  },
  textContainer: {
    textShadow: '0 0 36px rgba(0, 0, 0, 0.1)',

    [mediaQueries.breakpointLaptopSmall]: {
      margin: '324px 0 16px 0',
    },
    [mediaQueries.breakpointSmall]: {
      margin: '160px 0 16px 0',
    },
  },
  headline1: {
    letterSpacing: '1px',
    fontWeight: 800,
    fontSize: '52px',
    color: colors.black,
    lineHeight: 1,
    [mediaQueries.breakpointLaptopSmall]: {
      fontSize: '50px',
    },
    [mediaQueries.breakpointSmall]: {
      fontSize: '34px',
    },
  },
  headline2: {
    color: colors.brown,
    fontWeight: 800,
    fontSize: '30px',
    marginBottom: '40px',
    marginTop: '8px',
    [mediaQueries.breakpointLaptopSmall]: {
      fontSize: '30px',
      marginBottom: '8px',
    },
    [mediaQueries.breakpointSmall]: {
      fontSize: '20px',
    },
  },
  headline3: {
    fontSize: '30px',
    fontWeight: 700,
    color: colors.white,
    maxWidth: '400px',
    lineHeight: 1,
    [mediaQueries.breakpointLaptopSmall]: {
      fontSize: '28px',
    },
    [mediaQueries.breakpointSmall]: {
      fontSize: '16px',
    },
  },
  disclaimer: {
    marginTop: '16px',
    color: colors.white,
    textDecoration: 'none',
    fontWeight: '800',
    fontSize: '18px',
    letterSpacing: '1px',
    cursor: 'pointer',
    [mediaQueries.breakpointLaptopSmall]: {
      fontSize: '16px',
    },
  },
  desktopCalculator: {
    [mediaQueries.breakpointLaptopSmall]: {
      display: 'none',
    },
  },
  mobileCalculator: {
    display: 'none',
    [mediaQueries.breakpointLaptopSmall]: {
      display: 'initial',
    },
  },

  logo: {
    marginTop: '20px',
    width: '300px',
  },
});
