export default ({ colors, mediaQueries }) => ({
  consent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '10px',
    padding: '16px 0',
    borderBottom: `1px solid ${colors.grayLight}`,

    '&:last-child': {
      borderBottom: 'none',
    },
  },

  headingBar: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
  },

  tickIconWrapper: {
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  tickIcon: {
    width: '12px',
    height: '12px',
    '& *': {
      fill: colors.white,
    },
  },

  accepted: {
    backgroundColor: colors.secondary,
  },
  partially_accepted: {
    backgroundColor: colors.warning,

  },
  rejected: {
    backgroundColor: colors.error,
  },

  infoTag: {
    borderRadius: '30px',
    padding: '5px 8px',
    color: colors.white,
    fontSize: 12,
    fontWeight: 450,
    whiteSpace: 'nowrap',

    [mediaQueries.breakpointSmall]: {
      display: 'none',
    },
  },

  heading: {
    margin: 0,
    fontSize: '15px',
    fontWeight: 500,
  },

  text: {
    margin: 0,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 450,
    color: colors.black,

    '& p': {
      margin: 0,
    },
  },

  chooseChannelsButton: {
    background: 'transparent',
    outline: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
    fontWeight: 500,
    fontSize: '12px',
    color: colors.secondary,
    textDecoration: 'underline',
    transition: 'opacity 0.3s ease-in-out',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
});
