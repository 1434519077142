/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StylesheetParams } from '@common/types';

export const modalContent = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'visible',
  width: '100%',
};

export default ({ mediaQueries }: StylesheetParams) => ({
  root: {
    maxWidth: '480px',
    position: 'relative',
    transform: 'none',
    top: 0,
    left: 0,

    [mediaQueries.breakpointLaptopSmall]: {
      maxWidth: '100%',
    },
  },

  legalTermsRoot: {
    maxWidth: 'initial',
  },

  overlay: {
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  innerContent: {
    padding: '60px 32px 32px 32px',

    [mediaQueries.breakpointLaptopSmall]: {
      padding: '60px 28px 28px 28px',
    },
  },

  legalTermsInnerContent: {
    width: '50vw',
    height: '90vh',
    minWidth: '740px',

    [mediaQueries.breakpointLaptop]: {
      width: '70vw',
      minWidth: 'unset',
    },

    [mediaQueries.breakpointLaptopSmall]: {
      width: '80vw',
    },

    [mediaQueries.breakpointTablet]: {
      width: '100vw',
      height: '100%',
    },
  },

  closeButton: {
    transition: 'opacity 0.3s',
    backgroundColor: 'transparent',
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    width: '24px',
    top: '24px',
    right: '24px',

    '&:hover': {
      opacity: 0.65,
    },

    '& svg': {
      height: '16px',
      width: '16px',
      '& path': {
        fill: '#000',
      },
    },
  },

  title: {
    marginBottom: '24px',
  },

  modalContentWrapper: {
    ...modalContent,
  },
});
