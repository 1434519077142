import * as DashboardSection from '../../../components/DashboardSection';

import { compose } from 'redux';
import { connectSelectors, connectState } from '../../../../connect';
import { Hyperlink, SecondaryHeading } from '../../../../shared/components/typography';
import { memo, useCallback, useState } from 'react';
import { useLoanContext } from '../LoanContext';
import { useTranslator } from '../../../../../localizations';
import AdditionalAmount from './AdditionalAmount';
import Button from '../../../../shared/components/Button';
import classnames from 'classnames';
import DetailsRow from '../DetailsRow';
import Extension from './Extension';
import Help from '../Help';
import injectStyles from '4finance-components-pl';
import LoanSchedule from '../../../../shared/components/LoanSchedule';
import PaymentHeader from '../PaymentHeader';
import PenaltyInfo from '../PenaltyInfo';
import QA_CLASSES from '../../../../lib/qa';
import styles from './ActiveLoan.jss';
import Warning from '../../../components/Warning';

type Props = {
  classes: Record<string, any>;
  isAdditionalAmountAvailable: boolean;
};
const Schedule = connectSelectors({
  schedule: ['dashboard', 'latestLoanSchedule'],
})(LoanSchedule);

const ActiveLoan = (props: Props): JSX.Element => {
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const {
    classes,
    isAdditionalAmountAvailable,
  } = props;
  const {
    msg,
  } = useTranslator();
  const loanContext = useLoanContext();
  const {
    isLatePayment,
    isDebtCollection,
    canExtend,
  } = loanContext;
  const isLateOrDebt = isLatePayment || isDebtCollection;
  const toggleSchedule = useCallback(() => {
    setScheduleOpen(prevState => !prevState);
  }, []);
  const scheduleQaClass = scheduleOpen ? QA_CLASSES.LOAN_SCHEDULE_TABLE_CLOSE : QA_CLASSES.SCHEDULE_LINK;

  return (
    <>
      <DashboardSection.Block>
        <div className={classes.detailsHeadingContainer}>
          <SecondaryHeading bigger>
            {msg('dashboard.loan.details')}
          </SecondaryHeading>
          <Hyperlink onClick={toggleSchedule} className={classnames(classes.scheduleButton, scheduleQaClass)}>
            {scheduleOpen ? msg('actions.close') : msg('schedule.dashboard')}
          </Hyperlink>
        </div>
        {scheduleOpen ? <Schedule /> : (
          <>
            <div>
              <DetailsRow title={msg('dashboard.loan.active.repayable')} value={loanContext.repayableAmount} valueClassName={QA_CLASSES.REPAYABLE_AMOUNT} bold />
              <DetailsRow title={msg('dashboard.loan.active.principal')} value={loanContext.principalAmount} valueClassName={QA_CLASSES.PRINCIPAL_AMOUNT} />
              <DetailsRow title={msg('dashboard.loan.active.commission')} value={loanContext.initialCommissionAmount} valueClassName={QA_CLASSES.INITIAL_COMMISSION_AMOUNT} />
              <DetailsRow title={msg('dashboard.loan.active.interest')} value={loanContext.interestAmount} valueClassName={QA_CLASSES.INTEREST_AMOUNT} />
            </div>
            {canExtend && <Extension />}
          </>
        )}
      </DashboardSection.Block>
      <DashboardSection.Block yellow className={QA_CLASSES.PANEL_DETAILS}>
        {isAdditionalAmountAvailable && <AdditionalAmount />}
        <PaymentHeader amount={loanContext.nextInstallment.amount} title={msg('dashboard.loan.active.monthlyPayment')} />
        {isLateOrDebt && <PenaltyInfo amount={isLatePayment ? loanContext.delayedAmount : loanContext.schedulePayment} extra={loanContext.extraPaymentAmount} />}
        <DetailsRow title={msg('dashboard.loan.active.dueDate')} value={loanContext.nextInstallment.dueDate} className={classes.dueDate} valueClassName={QA_CLASSES.NEXT_INSTALLMENT} bold />
        {isLateOrDebt && (
        <Warning className={classnames(classes.warning, QA_CLASSES.LATE_OR_DEBT_INFO)}>
          {isLatePayment ? msg('dashboard.late_payment.warning') : msg('dashboard.debt_collection.warning')}
        </Warning>
        )}
        <Button to={'/payment'} className={classnames(classes.button, QA_CLASSES.PAYMENT_NEXT_STEP)}>
          {msg('dashboard.loan.pay')}
        </Button>
        {isLateOrDebt && <Help />}
      </DashboardSection.Block>
    </>
  );
};

ActiveLoan.styleRoot = 'ActiveLoan';
export default memo(compose<any>(
  connectState({
    isAdditionalAmountAvailable: ['api', 'fetch', 'client', 'query', 'data', 'additionalAmount'],
  }),
  injectStyles(styles),
)(ActiveLoan));
