import { extendTheme, normalizeColors, onea as theme } from '4finance-components-pl';
import { fromJS } from 'immutable';
import colors from '@browser/lib/styles/colors.jss';

const extendedTheme = extendTheme(theme, fromJS({
  globals: {
    colors: normalizeColors(colors),
    Button: () => ({
      kindPrimary: {
        backgroundColor: colors.primary,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.primaryDarker,
        },
      },
    }),
    '@global': {
      body: {
        minHeight: '100vh',
      },
    },
    borders: {
      calculator: '8px',
    },
  },
}));

export default extendedTheme;
