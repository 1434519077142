import { StylesheetParams } from '@common/types';

export default (({
  colors,
}: StylesheetParams) => ({
  separator: {
    height: '4px',
    backgroundColor: colors.primary,
    width: '108px',
    margin: '20px 0 20px -40px',
  },

  centered: {
    margin: '20px 0',
  },
}));
