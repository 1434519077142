import * as R from '../../../../routes/routesList';

import { CONSENT_COLA_IDS as C } from '../../../../../common/lib/constants';
import { compose } from 'redux';
import { connectPrefetch, connectState } from '../../../../connect';
import { SecondaryHeading } from '../../../../shared/components/typography';
import { snakeCase } from 'lodash';
import { useTranslator } from '../../../../../localizations';
import EditableRow from '../EditableRow/EditableRow';
import injectStyles from '4finance-components-pl';
import MarketingConsentsBlock from '@dynamic/settings/components/MarketingConsentsBlock';
import QA_CLASSES from '../../../../lib/qa';
import styles from './ClientSettings.jss';
import type { Map } from 'immutable';

const marketingConsentNames = {
  ACCEPT_DATA_SHARING: C.ACCEPT_DATA_SHARING,
  ACCEPT_INTERNAL_DATA_SHARING: C.ACCEPT_INTERNAL_DATA_SHARING,
  ACCEPT_NEWS: C.ACCEPT_NEWS,
};
const consentStatuses = ['disabled', 'partially_activated', 'partially_activated', 'activated'];
const uneditableFields = ['country'];

type Props = {
  address: Map<any, any>;
  phone: string;
  email: string;
  identityCardNumber: string;
  country: string;
  firstName: string;
  lastName: string;
  bankAccountNumber: string;
  consents: Map<any, any>;
  marketingConsentsStatus: boolean;
};

const ClientSettings = (props: Props): JSX.Element => {
  const {
    address,
    consents,
    phone,
    email,
    identityCardNumber,
    country,
    firstName,
    lastName,
    bankAccountNumber,
    marketingConsentsStatus,
  } = props;
  const {
    msg,
  } = useTranslator();
  const {
    location1: city,
    location4: street,
    postalCode: zip,
  } = address.toJS();
  const marketingConsents = consents?.toJS().filter((cons: {type: string, status: string}) => cons.type in marketingConsentNames && cons.status === 'GRANTED') || {};
  const consentsStatus = consentStatuses[marketingConsents.length];
  const isAddressEmpty = !city && !street && !zip;
  const rows = [
    ['name', `${firstName || '-'} ${lastName || '-'}`],
    ['email', email || '-'], ['password', '********'],
    ['identityCardNumber', identityCardNumber || '-'],
    ['phone', phone] || '-', ['country', country || '-'],
    ['address', !isAddressEmpty ? `${city || '-'}, ${street || '-'}, ${zip || '-'}` : '-'],
    ['bankAccountNumber', bankAccountNumber || '-'],
  ];

  if (!marketingConsentsStatus) {
    rows.push(['consents', msg(`dashboard.settings.change_consents.${consentsStatus}`)]);
  }
  const editableRows = rows.map(([key, value]) => {
    if (typeof key !== 'string') {
      return <span key={key?.toString()}>Błąd wczytywania danych</span>;
    }
    const editableRowProps = {
      pathname: R[`CHANGE_${snakeCase(key).toUpperCase()}` as keyof typeof R],
      buttonClass: QA_CLASSES[`CLIENT_${snakeCase(key).toUpperCase()}_EDIT_BUTTON` as keyof typeof QA_CLASSES],
      valueClass: QA_CLASSES[`CLIENT_${snakeCase(key).toUpperCase()}_EDIT_VALUE` as keyof typeof QA_CLASSES],
    };

    return <EditableRow key={key} title={msg(`form.${key}.label`)} value={value} editable={!uneditableFields.includes(key)} {...!uneditableFields.includes(key) && editableRowProps} />;
  });

  return (
    <>
      <SecondaryHeading bigger>
        {msg('dashboard.settings.client_data')}
      </SecondaryHeading>
      {editableRows}
      {marketingConsentsStatus && <MarketingConsentsBlock />}
    </>
  );
};

ClientSettings.styleRoot = 'ClientSettings';
export default compose<any>(
  connectPrefetch([
    ['api', 'fetchClientConsents'],
    ['api', 'fetchClientIdentityDocument'],
    ['consents', 'fetchMarketingConsentsStatus'],
  ]),
  connectState({
    address: ['api', 'fetch', 'client', 'query', 'data', 'declaredAddress'],
    consents: ['api', 'fetch', 'client', 'consents', 'query', 'data', 'consents'],
    phone: ['api', 'fetch', 'client', 'query', 'data', 'mobilePhone', 'mobilePhone'],
    email: ['api', 'fetch', 'client', 'query', 'data', 'email', 'email'],
    identityCardNumber: ['api', 'fetch', 'client', 'identityDocument', 'query', 'data', 'documentNumber'],
    bankAccountNumber: ['api', 'fetch', 'client', 'query', 'data', 'bankAccount'],
    country: ['api', 'fetch', 'client', 'query', 'data', 'placeOfBirth'],
    firstName: ['api', 'fetch', 'client', 'query', 'data', 'firstName'],
    lastName: ['api', 'fetch', 'client', 'query', 'data', 'lastName'],
    marketingConsentsStatus: ['consents', 'status'],
  }),
  injectStyles(styles),
)(ClientSettings);
