export default (({
  colors,
}) => ({
  container: {
    paddingTop: '20px',
  },
  separator: {
    height: '4px',
    backgroundColor: colors.primary,
    width: '108px',
    margin: '20px 0 20px -40px',
  },
}));
