export default ({ colors, mediaQueries }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    color: colors.white,
  },
  heading: {
    lineHeight: 1,
  },
  amount: {
    fontSize: '22px',
    color: colors.white,
    [mediaQueries.breakpointSmall]: {
      fontSize: '12px',
    },
    '& span:first-child': {
      fontSize: '44px',
      fontWeight: 600,
      [mediaQueries.breakpointSmall]: {
        fontSize: '30px',
      },
    },
  },
});
