import { StylesheetParams } from '@common/types';

export default ({ colors, mediaQueries }: StylesheetParams): Record<string, any> => ({
  detailsHeadingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  scheduleButton: {
    lineHeight: 0.9,
    textAlign: 'right',
    [mediaQueries.breakpointSmall]: {
      fontSize: '14px',
    },
  },
  button: {
    width: '100%',
  },
  dueDate: {
    borderBottom: 'none',
    marginTop: '10px',
    color: colors.white,
  },
  warning: {
    margin: '16px 0',
  },
});
