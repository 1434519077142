const colors = {
  red: '#cd1b1f',
  rejectRed: '#fd3c4f',
  errorLight: '#FFF6F6',
  yellow: '#52D800',
  black: '#000',
  brown: '#51302e',
  white: '#fff',
  beigeWhite: '#f8f3ff',
  veryLightGray: '#f8f8f8',
  lightGray: '#f8f3ff',
  mediumLightGray: '#ececec',
  mediumLightGray2: '#e6e6e6',
  gray: '#b3b3b3',
  grey3: '#333',
  mediumGray: '#868686',
  mediumDarkGray: '#474747',
  darkGray: '#363636',
  lightBlue: '#90dceb',
  primary: '#52D800',
  primaryDarker: '#27AD00',
  primaryHover: '#27AD00',
  secondary: '#954FEF',
  secondaryDarker: '#3C1C47',
  vivigoLight: '#EAFD9E',
  vivigoPrimary: '#52D800',
  vivigoPrimaryDarker: '#52D800',
  vivusSecondary: '#006d38',
  inputColor: '#474747',
  inputBorder: '#d3d3d3',
};

export default colors;
