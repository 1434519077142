export default ({ colors, mediaQueries }) => ({
  container: {
    flex: '0 0 25%',
    padding: '0 16px',
    [mediaQueries.breakpointLaptopSmall]: {
      flex: '0 0 50%',
      marginBottom: '60px',
    },
    [mediaQueries.breakpointTablet]: {
      flex: '0 0 100%',
      marginBottom: '24px',
      transition: 'max-height 0.6s',
      maxHeight: '56px',
      overflow: 'hidden',
    },
    '&:not(.open) $title': {
      [mediaQueries.breakpointTablet]: {
        color: colors.secondary,
      },
    },
    '&.open': {
      maxHeight: '800px',
      '& $dropdownIcon': {
        [mediaQueries.breakpointTablet]: {
          transform: 'rotate(0)',
          '& svg *': {
            fill: colors.white,
          },
        },
      },
    },
  },
  title: {
    textTransform: 'uppercase',
    color: colors.white,
    fontSize: '16px',
    marginBottom: '16px',
    fontWeight: 500,
    [mediaQueries.breakpointTablet]: {
      width: '100%',
      margin: '16px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'color 0.3s',
    },
  },
  dropdownIcon: {
    display: 'none',
    transition: 'transform 0.3s',
    transform: 'rotate(180deg)',
    height: '16px',
    '& svg': {
      height: '16px',
      '& *': {
        fill: colors.secondary,
      },
    },
    [mediaQueries.breakpointTablet]: {
      display: 'block',
    },
  },
  separator: {
    backgroundColor: colors.primary,
    height: '2px',
    width: '30px',
    margin: '16px 0 16px -10px',
    [mediaQueries.breakpointTablet]: {
      width: '100%',
      margin: '16px 0',
    },
  },
});
