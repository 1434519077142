export default (({
  colors,
}) => ({
  root: {
    padding: '16px 0',
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  title: {
    marginRight: '8px',
  },
  container: {
    maxWidth: '970px',
  },
}));
