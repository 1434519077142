import * as calculator from '../calculator/actions';
import { ActionWithPayload, DispatchType } from '@common/types';
import { actions as api } from '../api';
import { createApplication, extractConsentsFromFormData, updateApplication } from '../registration/actions';
import { TEMPORARY_CONSENTS } from '@common/lib/constants';

export const SET_SELECTED_PAYMENT = 'SET_SELECTED_PAYMENT';

export interface SetSelectedPayment {
  type: typeof SET_SELECTED_PAYMENT
  payload: 'fullRepayment' | 'currentInstallment'
}

export type DashboardActions = SetSelectedPayment

type DispatchPromise = DispatchType<Promise<Response>>
type ActionWithPromise = ActionWithPayload<Promise<Response>>

export function setSelectedPayment(paymentType: 'fullRepayment' | 'currentInstallment'): SetSelectedPayment {
  return {
    type: SET_SELECTED_PAYMENT,
    payload: paymentType,
  };
}
export function fetchLatestLoan():
  (a: { dispatch: DispatchPromise }) => ActionWithPromise {
  return ({
    dispatch,
  }) => {
    return dispatch(api.fetchClientLoansByLoanid('latest'));
  };
}
export function fetchLatestLoanSchedule():
(a: { dispatch: DispatchPromise }) => ActionWithPromise {
  return ({
    dispatch,
  }) => {
    return dispatch(api.fetchClientLoansPaymentScheduleByLoanid('latest'));
  };
}
export function fetchProposalOffer(): (a: {
  dispatch: DispatchPromise;
  getApiResponse: (arg: string[]) => Record<string, any>;
}) => Promise<ActionWithPromise> {
  return async ({ dispatch, getApiResponse }) => {
    const application = await getApiResponse(['fetch', 'client', 'application']);
    // eslint-disable-next-line no-underscore-dangle
    const proposal = application?.proposal;

    return dispatch(api.fetchClientApplicationOffer(proposal.amount, proposal.term, 'default'));
  };
}

export function acceptProposal(
  data: Record<string, any>,
  withChannels: boolean,
): (a: { dispatch: DispatchPromise; getApiResponse: (arg: string[]) => Record<string, any> }) => Promise<void> {
  return async ({ dispatch, getApiResponse }) => {
    const application = await getApiResponse(['fetch', 'client', 'application']);
    // eslint-disable-next-line no-underscore-dangle
    const proposal = application?.proposal;
    const consents = extractConsentsFromFormData(data);

    if (!proposal) {
      throw new Error('Client does not have a pending proposal.');
    }

    dispatch(calculator.setAmount(proposal.amount, 'proposal'));
    dispatch(calculator.setTerm(proposal.term, 'proposal'));
    await dispatch(createApplication({ id: 'proposal', consents, withChannels }));
  };
}
