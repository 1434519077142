import { PromiseType } from 'utility-types';

export const SET_CHANNELS_FORM_NAME = 'SET_CHANNELS_FORM_NAME';
export const SET_ACTIVE_CHANNELS_MODAL = 'SET_ACTIVE_CHANNELS_MODAL';
export const CLEAR_ACTIVE_CHANNELS_MODAL = 'CLEAR_ACTIVE_CHANNELS_MODAL';
export const FETCH_MARKETING_CONSENTS_STATUS = 'FETCH_MARKETING_CONSENTS_STATUS';

export interface SetActiveChannelModal {
  type: typeof SET_ACTIVE_CHANNELS_MODAL
  payload: string
}

export function fetchMarketingConsentsStatus() {
  return ({ httpGet }: PromiseType<Promise<Record<string, any>>>): Record<string, any> => ({
    type: FETCH_MARKETING_CONSENTS_STATUS,
    payload: {
      promise: httpGet(
        '/marketing/consents/status',
      ),
    },
  });
}

export function setActiveMarketingChannelsModal(type: string): SetActiveChannelModal {
  return {
    type: SET_ACTIVE_CHANNELS_MODAL,
    payload: type,
  };
}

export function setChannelsFormName(formName: string): { type: string; payload: string } {
  return {
    type: SET_CHANNELS_FORM_NAME,
    payload: formName,
  };
}

export function clearActiveMarketingChannelsModal(): { type: string } {
  return {
    type: CLEAR_ACTIVE_CHANNELS_MODAL,
  };
}
