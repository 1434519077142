import { StylesheetParams } from '@common/types';

export default ({ colors }: StylesheetParams) => ({
  html: {
    height: '100%',
    fontSize: '9px',
  },
  body: {
    fontFamily: '"Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '1.2',
    color: colors.black,
    backgroundColor: colors.beigeWhite,
    height: '100%',
    margin: 0,
    position: 'relative',
  },
  a: {
    color: colors.primary,
    textDecoration: 'none',
  },
  '#app': {
    height: '100%',
  },
  '.page': {
    minHeight: '100vh',
  },
  'strong, b': {
    fontWeight: 600,
  },
  'input::-ms-clear': {
    display: 'none',
  },
  'input::-ms-reveal': {
    display: 'none',
  },
});
