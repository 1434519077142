import * as R from '../../routes/routesList';

import { compose } from 'redux';
import { connectActions } from '../../connect';
import { Section } from '../../shared/components/layout';
import { useCallback } from 'react';
import { useTranslator } from '../../../localizations';
import config from '4finance-configuration-pl';
import extendedTheme from '../../shared/components/extendedTheme';
import injectStyles from '4finance-components-pl';
import PasswordRecoveryApp from '4finance-password-recovery-pl';
import passwordStrictPasswordRecovery from '@common/validations/passwordStrictPasswordRecovery';
import phoneNumberValidation from './phoneNumberValidation';
import sendGTMEvent from '../../../common/lib/sendGTMEvent';
import styles from './PasswordRecovery.jss';

const primary = extendedTheme.getIn(['globals', 'colors', 'primary']);
const primaryDarker = extendedTheme.getIn(['globals', 'colors', 'primaryDarker']);
const secondary = extendedTheme.getIn(['globals', 'colors', 'secondary']);
const white = extendedTheme.getIn(['globals', 'colors', 'white']);
const customStyles = {
  PasswordRecovery: {
    component: {
      marginTop: '60px',
    },
    container: {
      borderRadius: '8px',
      '& input': {
        border: '1px solid #D3D3D3',
      },

      '& button': {
        backgroundColor: primary,

        '&:hover': {
          backgroundColor: primaryDarker,
        },
      },
    },
  },
  FieldContainer: {
    wrapper__focused: {
      borderBottom: `2px solid ${primary}`,
    },
    indicator: {
      border: `2px solid ${primary}`,
      backgroundColor: primary,
    },
  },
  ResendButton: {
    component: {
      color: primary,
    },
  },
  UserError: {
    container: {
      '& button': {
        color: `${white} !important`,
      },
    },
  },
  NewPasswordSaved: {
    container: {
      '& button': {
        color: `${white} !important`,
      },
    },
  },
  globals: {
    colors: {
      titleIconsColor: primary,
      contactIconsColor: primary,
      primary,
      secondary,
    },
  },
};

type Props = {
  classes: Record<string, any>;
  updatePath: (...args: Array<any>) => any;
};

const PasswordRecovery = (props: Props): JSX.Element => {
  const {
    updatePath,
    classes,
  } = props;
  const {
    msg,
  } = useTranslator();
  const onGoToLogin = useCallback(() => updatePath(R.LOGIN), [updatePath]);
  const onContactSupport = useCallback(() => typeof window !== 'undefined' && window.location.assign(R.CONTACT), [typeof window]);
  const translations = {
    en: msg('passwordRecovery'),
  };
  const onSuccessRecover = useCallback(({
    recoverType,
  }: Record<string, any>) => {
    sendGTMEvent({
      event: 'pass_recovery_success',
      recoverType,
    });
  }, []);
  const apiKey = config.environment === 'production' ? config.passwordRecovery.captchaApiKey : config.passwordRecovery.captchaTestApiKey;

  return (
    <Section gray className={classes.root}>
      <PasswordRecoveryApp
        apiUrl={config.passwordRecovery.apiUrl}
        captchaApiKey={apiKey}
        captchaLanguage={config.passwordRecovery.captchaLanguage}
        phoneNumberFormat={/([0-9]{9})/}
        theme={'onea'}
        customStyles={customStyles}
        customPasswordValidation={passwordStrictPasswordRecovery}
        customPhoneValidation={phoneNumberValidation}
        strongPassword={false}
        translations={translations}
        onBackToSignInClick={onGoToLogin}
        onSuccessRecover={onSuccessRecover}
        onSuccessRecoverButtonClick={onGoToLogin}
        onContactSupportClick={onContactSupport}
        initiateWithCaptcha
        isCaptchaInvisible
        mobilePhoneRecoveryOnly
      />
    </Section>
  );
};

PasswordRecovery.styleRoot = 'PasswordRecovery';
export default compose<any>(connectActions({
  updatePath: ['router', 'updatePath'],
}), injectStyles(styles))(PasswordRecovery);
