import { camelCase } from 'lodash';
import { compose } from 'redux';
import { connectActions, connectPending, connectState } from '@browser/connect';
import { connectField, Form, SetFormFieldProperty } from '4finance-onion-form-pl';
import { GRANTED_STATUS, MARKETING_CHANNELS_MODEL } from '@common/lib/constants';
import { Map } from 'immutable';
import { PATCH_CLIENT_CONSENTS } from '@common/settings/actions';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import consentsJSON from '../../statementGroup';
import injectStyles, { Button, Checkbox, IconCloseThin, Loader } from '4finance-components-pl';
import Modal from '@shared/components/Modal';
import styles from './MarketingChannelsModal.jss';
import translate from '@localizations/translate';

const CHECKBOX_STYLE = { style: { container: { marginBottom: 25, maxWidth: '100%' } } };

export const convertFieldIdToColaConsent = (fieldId: string) => fieldId
  .replace(/([a-z])([A-Z])/g, '$1_$2')
  .toUpperCase()
  .replace('ACCEPT_', '');

type Props = {
  classes: Record<string, string>;
  error: string;
  cnt: (arg0: string) => Node;
  msg: (key: string) => string;
  fetchClientConsents: () => any;
  patchClientConsents: (...args: Array<any>) => any;
  formName: string;
  setFormFieldProperty: SetFormFieldProperty;
  clearActiveMarketingChannelsModal: () => { type: string };
  formsValues: Map<any, any>;
  activeChannelsModal: string;
  webapiConsents?: Map<any, any>;
  pending: boolean;
}

const MarketingChannelsModal = ({
  cnt,
  msg,
  classes,
  error,
  formName,
  setFormFieldProperty,
  formsValues,
  clearActiveMarketingChannelsModal,
  activeChannelsModal,
  patchClientConsents,
  webapiConsents,
  fetchClientConsents,
  pending,
}: Props) => {
  const isProfile = formName === 'settings';
  const consents = consentsJSON.marketingChannels.fields.statements[activeChannelsModal];

  const getInitialState = useCallback(() => {
    const model = MARKETING_CHANNELS_MODEL[activeChannelsModal];
    const res = {};

    model && model.forEach(consent => {
      const colaConsent = convertFieldIdToColaConsent(consent);
      const consentValue = webapiConsents?.toJS().find(cnsnt => cnsnt.type === colaConsent).status === GRANTED_STATUS;

      res[consent] = consentValue || false;
    });

    return res;
  }, [activeChannelsModal, webapiConsents]);

  const [consentsState, setConsentsState] = useState(getInitialState());

  useEffect(() => {
    if (isProfile) {
      setConsentsState(getInitialState());
    }
  }, [isProfile, getInitialState]);

  const getAllConsentValues = useCallback(() => {
    const formValues = Object.entries(formsValues?.toJS()[formName] || {})
      .filter(([key]) => key.includes(activeChannelsModal) && key !== activeChannelsModal)
      .reduce((acc, [key, value]) => {
        if (value && typeof value === 'object') {
          acc[key] = value.value;
        }

        return acc;
      }, {});

    return formValues;
  }, [formsValues, activeChannelsModal]);

  const onSaveAndSend = async () => {
    if (isProfile) {
      await patchClientConsents(consentsState);
      fetchClientConsents();
    } else {
      const areAllConsentsChecked = getAreAllConsentsChecked();

      setFormFieldProperty(formName, activeChannelsModal, 'value', areAllConsentsChecked);
    }

    clearActiveMarketingChannelsModal();
  };

  const onClose = (): void => {
    clearActiveMarketingChannelsModal();
  };

  const onMarkAllClick = () => {
    if (isProfile) {
      setConsentsState(Object.keys(consentsState).reduce((acc, consent) => {
        acc[consent] = true;

        return acc;
      }, {}));
    } else {
      Object.entries(formsValues.toJS()[formName]).forEach(([key]) => {
        if (key.includes(activeChannelsModal) && key !== activeChannelsModal) {
          setFormFieldProperty(formName, key, 'value', true);
        }
      });
    }
  };

  const getAreAllConsentsChecked = () => {
    const allConsentsValues = getAllConsentValues();

    return Object.values(allConsentsValues).every(consent => consent === true);
  };

  const getConsentValue = useCallback((fieldId) => (isProfile ? consentsState[fieldId] : formsValues.getIn([formName, fieldId, 'value']) || false), [consentsState, formsValues, formName, isProfile]);

  const onChangeHandler = (params) => {
    const { name, value } = params;

    if (isProfile) {
      setConsentsState({ ...consentsState, [name]: value });
    } else {
      setFormFieldProperty(formName, name, value);
    }
  };

  return (
    <>
      <Modal
        passedClasses={classes.wrapper}
        open={!!activeChannelsModal}
        onClose={onClose}
        passedInnerContentClasses={classes.innerWrapper}
        classes={{ modalContentWrapper: classes.modalContentWrapper }}
      >
        <div className={classes.topBar}>
          <div className={classes.title}>
            {msg(`marketing_channels.title_${activeChannelsModal}`)}
          </div>
          <button type="button" className={classes.closeButton} onClick={onClose}>
            <IconCloseThin />
          </button>
        </div>
        <Button
          disabled={pending}
          onClick={onMarkAllClick}
          kind="simple"
          className={classes.markAllButton}
        >
          {msg('marketing_channels.mark_all')}
        </Button>
        <p className={classes.text}>
          {msg(`marketing_channels.text_${activeChannelsModal}`)}
        </p>
        <div className={classes.formWrapper}>
          <Form name={formName} autocomplete={false}>
            {consents ? consents.map((consent) => {
              const { fields: { type, text } } = consent;
              const label = <div dangerouslySetInnerHTML={{ __html: text }} />;
              const fieldId = camelCase(type);

              const ConnectedField = translate(
                connectField(
                  fieldId,
                  {
                    label,
                    value: getConsentValue(fieldId),
                    key: `${fieldId}_checkbox`,
                    isExpandable: false,
                    bottomMargin: true,
                    ...CHECKBOX_STYLE,
                  },
                )(Checkbox),
              );

              return (
                // eslint-disable-next-line react/jsx-key
                <ConnectedField onChange={onChangeHandler} />
              );
            }) : <Loader />}
            {error && <div className={classes.error}>{msg(`app.errors.${error}`)}</div>}
          </Form>
        </div>
        <p className={cn(classes.text, classes.text2)}>
          {msg('marketing_channels.text_2')}
        </p>
        <p className={classes.infoHeading}>
          {msg('marketing_channels.info_heading')}
        </p>
        <p className={classes.text}>{cnt('marketing_channels.info')}</p>
        <Button onClick={onSaveAndSend} kind="dark" className={classes.consentButton}>
          {msg('marketing_channels.close_and_save')}
        </Button>
      </Modal>
    </>
  );
};

MarketingChannelsModal.styleRoot = 'MarketingChannelsModal';

export default compose(
  connectState({
    error: ['app', 'error'],
    marketingConsents: ['api', 'fetch', 'client', 'consents', 'marketing', 'query', 'data'],
    formsValues: ['onionForm', 'fields'],
    formName: ['consents', 'formName'],
    activeChannelsModal: ['consents', 'activeChannelsModal'],
    webapiConsents: ['api', 'fetch', 'client', 'consents', 'query', 'data', 'consents'],
  }),
  connectActions({
    updatePath: ['router', 'updatePath'],
    patchClientConsents: ['settings', 'patchClientConsents'],
    setFormFieldProperty: ['onionForm', 'setFormFieldProperty'],
    clearActiveMarketingChannelsModal: ['consents', 'clearActiveMarketingChannelsModal'],
    fetchClientConsents: ['api', 'fetchClientConsents'],
  }),
  connectPending({
    pending: PATCH_CLIENT_CONSENTS,
  }),
  translate,
  injectStyles(styles),
)(MarketingChannelsModal);
