export default (({
  colors,
  mediaQueries,
}) => ({
  outerContainer: {
    backgroundColor: colors.secondaryDarker,
    padding: '60px 0',
    [mediaQueries.breakpointTablet]: {
      padding: '40px 0',
    },
  },
  innerContainer: {
    margin: '0 -16px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  legal: {
    backgroundColor: colors.secondaryDarker,
    color: colors.mediumGray,
    textAlign: 'center',
    padding: '24px 16px',
  },
}));
