export default ({ colors, mediaQueries }) => ({
  wrapper: {
    backgroundColor: colors.white,
    padding: '25px 30px',
    borderRadius: '10px',
    margin: '10px auto 40px auto',

    [mediaQueries.breakpointTablet]: {
      padding: '20px 10px',
    },
  },

  heading: {
    margin: '0 0 10px 0',
  },

  consentsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },

  markAllButton: {
    marginTop: '10px',
  },
});
