
import { BodyText, SecondaryHeading } from '@shared/components/typography';
import { compose } from 'redux';
import { connectActions, connectPrefetch, connectState } from '@browser/connect';
import { connectSubmit, Form, SetFormFieldProperty } from '4finance-onion-form-pl';
import { CONSENT_PROPERTY_NAMES_BY_COLA_ID, MARKETING_CHANNELS_MODEL, MARKETING_CONSENTS } from '@common/lib/constants';
import { extractConsentsFromFormData } from '@common/registration/actions';
import { Offer } from '@browser/calculator/components';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { RootState } from '@browser/configureStoreWithHistory';
import { Section } from '@shared/components/layout';
import { useRenderConsentContentWithSchedule } from '@shared/components/AcceptInformationFormConsent';
import { useTranslator } from '@localizations';
import Button from '@shared/components/Button';
import Consents from '@dynamic/components/Consents';
import CrossCheckErrors from './CrossCheckErrors';
import injectStyles from '4finance-components-pl';
import Loading from '@shared/components/Loading';
import MarketingChannelsModal from '@dynamic/registration/components/MarketingChannelsModal';
import QA_CLASSES from '@browser/lib/qa';
import styles from './ConfirmApplication.jss';
import type { ImmutableMap } from '@common/lib/types';

const Submit = connectSubmit(Button);

type Props = {
  classes: Record<string, any>;
  consents: ImmutableMap | null | undefined;
  createAndUpdateApplication: (...args: Array<any>) => any;
  setMultipleFields: (...args: Array<any>) => any;
  setFormFieldProperty: SetFormFieldProperty;
  setAmount: (...args: Array<any>) => any,
  setTerm: (...args: Array<any>) => any,
  fetchOffer: (...args: Array<any>) => any,
  fetchClientApplicationOffer: (...args: Array<any>) => any;
  offer: ImmutableMap | null | undefined;
  amount: number;
  declarationWarning: boolean;
  term: number;
  loanCount: number;
  consentsStatus: boolean,
};

type Consent = {
  status: 'GRANTED' | 'WITHDRAWN';
  type: string;
};

const toSnakeFormat = (channel: string) => {
  return channel.replace(/([a-z])([A-Z])/g, '$1_$2')
    .toUpperCase();
};

const ConfirmApplication = (props: Props): JSX.Element => {
  const {
    classes,
    consents,
    offer,
    createAndUpdateApplication,
    setMultipleFields,
    fetchClientApplicationOffer,
    amount,
    term,
    setAmount,
    setTerm,
    fetchOffer,
    loanCount,
    declarationWarning,
    setFormFieldProperty,
    consentsStatus,
  } = props;
  const { msg } = useTranslator();

  const acceptedMarketingConsents = useMemo(() => {
    return consents
      ? consents
        .toJS()
        .filter((c: Consent) => c.status === 'GRANTED' && MARKETING_CONSENTS.includes(c.type))
        .map((c: Consent) => c.type)
      : [];
  }, [consents]);

  const acceptedMarketingConsentsNew = useMemo(() => {
    const convertToSnakeCase = (input: typeof MARKETING_CHANNELS_MODEL): Record<string, string[]> => {
      const output: Record<string, string[]> = {};

      Object.entries(input).forEach(([key, values]) => {
        const newKey = toSnakeFormat(key);

        output[newKey] = values.map(value =>
          toSnakeFormat(value.replace('accept', '')));
      });

      return output;
    };

    const getGrantedConsents = (channelsModel: Record<string, string[]>): string[] => {
      const isConsentGrantedPredicate = (channels: string[]) => {
        return consents ? consents
          .toJS()
          .filter((c: Consent) => c.status === 'GRANTED' && channels.includes(c.type))
          .map((c: Consent) => c.type)
          .length === channels.length
          : false;
      };

      return Object.entries(channelsModel)
        .filter(([, channels]) => isConsentGrantedPredicate(channels))
        .map(([consent]) => consent);
    };

    const marketingChannelsModelSnakeCase = convertToSnakeCase(MARKETING_CHANNELS_MODEL);

    return getGrantedConsents(marketingChannelsModelSnakeCase);
  }, [consents]);

  const finalAcceptedMarketingConsents = consentsStatus ? acceptedMarketingConsentsNew : acceptedMarketingConsents;

  const hiddenConsents: string[] = useMemo(() => {
    if (!consents) {
      return MARKETING_CONSENTS;
    }

    return MARKETING_CONSENTS.filter((c) => finalAcceptedMarketingConsents.includes(c));
  }, [consents, acceptedMarketingConsents, acceptedMarketingConsentsNew, consentsStatus]);

  useEffect(() => {
    if (!amount) {
      setAmount(parseInt(localStorage.getItem('amount') || '', 10), 'default');
      setTerm(parseInt(localStorage.getItem('term') || '', 10), 'default');
      fetchOffer();
    }
  }, []);

  useEffect(() => {
    if (amount && term) {
      fetchClientApplicationOffer(amount, term, 'default');
    }
  }, [amount, term, fetchClientApplicationOffer]);

  const cleanMarketingConsentsSelection = () => {
    Object.keys(MARKETING_CHANNELS_MODEL).forEach(marketingConsent => {
      setMultipleFields('confirmApplication', 'value', { [marketingConsent]: false });
    });
  };

  const selectAlreadyGrantedMarketingChannels = () => {
    Object.entries(MARKETING_CHANNELS_MODEL)
      .forEach(([, channels]) => {
        channels.forEach(channel => {
          const channelSnaked = toSnakeFormat(channel.replace('accept', ''));

          const isChannelAlreadyGranted: boolean = consents ? consents
            .toJS()
            .filter((c: Consent) => c.status === 'GRANTED' && channelSnaked === c.type)
            .length === 1
            : false;

          if (isChannelAlreadyGranted) {
            setMultipleFields('confirmApplication', 'value', { [channel]: true });
          }
        });
      });
  };

  useEffect(() => {
    consentsStatus && cleanMarketingConsentsSelection();
    const values = (finalAcceptedMarketingConsents as string[]).reduce(
      (all, c) => ({
        [CONSENT_PROPERTY_NAMES_BY_COLA_ID[c] as string]: true,
        ...all,
      }),
      {},
    );

    setMultipleFields('confirmApplication', 'value', values);
    consentsStatus && selectAlreadyGrantedMarketingChannels();

    return () =>
      setMultipleFields('confirmApplication', 'apiError', {
        mobilePhone: null,
        email: null,
        identityCardNumber: null,
      });
  }, [acceptedMarketingConsents, acceptedMarketingConsentsNew, consentsStatus]);

  const formName = 'confirmApplication';

  const onSubmit = useCallback(({ values }) => {
    if (!values.declaration && !declarationWarning) {
      setFormFieldProperty(formName, 'declaration', 'warning', true);

      return;
    }

    const consentsFromForm = extractConsentsFromFormData(values);

    createAndUpdateApplication({ consents: consentsFromForm, withChannels: consentsStatus });
  }, [declarationWarning, consentsStatus]);
  const renderConsentContent = useRenderConsentContentWithSchedule('application');

  const finalRegistrationSetName = consentsStatus ? 'RegistrationNewMC' : 'Registration';

  const finalOldCustomerSetName = consentsStatus ? 'OldCustomerNewMC' : 'OldCustomer';

  const setName = loanCount && loanCount > 0 ? finalOldCustomerSetName : finalRegistrationSetName;

  return (
    <Section gray title={msg('registration.confirm.title')} separator>
      <BodyText>{msg('registration.confirm.note')}</BodyText>
      <div className={classes.formContainer}>
        <Form name={formName} onSubmit={onSubmit}>
          <div className={classes.container}>
            <SecondaryHeading className={classes.header} bigger>
              {msg('registration.calculator.details')}
            </SecondaryHeading>
            {!offer && <Loading />}
            <Offer
              offer={offer?.toJS?.()}
              pending={!offer}
              classes={{
                container: classes.offer,
              }}
            />
            <div className={classes.consents}>
              <Consents
                setName={setName}
                formName={'confirmApplication'}
                hide={hiddenConsents}
                renderContent={renderConsentContent}
              />
            </div>
          </div>
          <Submit className={QA_CLASSES.ACCOUNT_NEXT_STEP}>{msg('actions.accept')}</Submit>
        </Form>
        <CrossCheckErrors />
      </div>
      {consentsStatus && <MarketingChannelsModal />}
    </Section>
  );
};

ConfirmApplication.styleRoot = 'ConfirmApplication';
export default compose<() => ReactElement<Props>>(
  connectPrefetch([
    ['api', 'fetchClientConsents'],
    ['consents', 'fetchMarketingConsentsStatus'],
  ]),
connectActions({
  setAmount: ['calculator', 'setAmount'],
  setTerm: ['calculator', 'setTerm'],
  fetchOffer: ['calculator', 'fetchOffer'],
  createAndUpdateApplication: ['registration', 'createApplication'],
  setMultipleFields: ['onionForm', 'setMultipleFields'],
  setFormFieldProperty: ['onionForm', 'setFormFieldProperty'],
  fetchClientApplicationOffer: ['api', 'fetchClientApplicationOffer'],
}),
connectState((_props: Props, state: RootState) => {
  const { calculator: calculatorState } = state;
  const calculator = calculatorState.get('default') as Map<string, Record<string, unknown>>;

  if (!calculator.get('amount')) {
    return {};
  }

  const amount = calculator.get('amount')?.toString() || localStorage.getItem('amount');
  const term = calculator.get('term')?.toString() || localStorage.getItem('term');

  return {
    consents: ['api', 'fetch', 'client', 'consents', 'query', 'data', 'consents'],
    offer: [
      'api',
      'fetch',
      'client',
      'application',
      'offer',
      amount?.toString?.(),
      term?.toString?.() || '',
      'default',
      'data',
    ],
    amount: ['calculator', 'default', 'amount'],
    term: ['calculator', 'default', 'term'],
    loanCount: ['api', 'fetch', 'client', 'loans', 'query', 'data', 'clientLoanCount'],
    declarationWarning: ['onionForm', 'fields', 'confirmApplication', 'declaration', 'warning'],
    consentsStatus: ['consents', 'status'],
  };
}),
injectStyles(styles))(ConfirmApplication);
