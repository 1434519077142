export const FONTS: Record<string, { weight: string; name: string }[]> = {
  Poppins: [
    {
      weight: 'variable',
      name: 'Poppins-Variable',
    },
  ],
};

const fonts: Record<string, any>[] = Object.keys(FONTS).reduce((acc: Record<string, any>[], fontFamily: string) => [
  ...acc,
  ...(FONTS[fontFamily] || []).map(({ name, weight }: { name: string; weight: string }) => ({
    fontFamily,
    fontStyle: 'normal',
    fontWeight: weight,
    fontDisplay: 'fallback',
    src: `url('/fonts/${name}.woff2') format('woff2')`,
  })),
], []);

export default {
  '@font-face': fonts,
};
