export default ({ colors }): any => ({
  root: {
    textDecoration: 'underline',
    marginTop: '16px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    color: colors.secondary,
  },
});
