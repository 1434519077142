import * as R from '../../../routes/routesList';

import { Classes } from '@common/types';
import { compose } from 'redux';
import { PickByValue, ValuesType } from 'utility-types';
import { SecondaryHeading } from '@browser/shared/components/typography';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import ActiveLoan from '@browser/dynamic/dashboard/components/ActiveLoan';
import Button from '@browser/shared/components/Button';
import DashboardSection from '../DashboardSection';
import InfoIcon from '../../../../../assets/icons/info-icon.svg';
import injectStyles from '4finance-components-pl';
import LoanContext from '@browser/dynamic/dashboard/components/LoanContext';
import LockIcon from '../../../../../assets/icons/lock-icon.svg';
import Modal from '@browser/shared/components/Modal';
import NoActiveLoan from '@browser/dynamic/dashboard/components/NoActiveLoan';
import Settings from '@browser/dynamic/settings';
import styles from './DocumentsModal.jss';
import useTranslator from '@localizations/useTranslator';
import withLatestLoan from '@browser/dynamic/dashboard/withLatestLoan';

type Props = {
  classes: Classes<typeof styles>,
  latestLoan?: any | null
}

type RouteName = ValuesType<PickByValue<typeof R, string>>

type PageTitles = Record<RouteName, string>

const PAGE_TITLES: Partial<PageTitles> = {
  [R.PAYMENT]: 'dashboard.payment.title',
  [R.EXTENSION]: 'dashboard.extension.title',
};

const DocumentsModal = ({ classes, latestLoan }: Props) => {
  const [modalOn, setModalOn] = useState(false);
  const { location: { state, pathname }, goBack } = useHistory<{ fromDashboard: boolean }>();
  const { msg } = useTranslator();
  const instructionSteps = [1, 2, 3, 4, 5, 6];
  const title = msg(PAGE_TITLES[pathname as RouteName] || 'dashboard.loan.title');

  useEffect(() => {
    if (pathname === '/agreements') {
      setModalOn(true);
    } else {
      setModalOn(false);
    }
  }, []);

  const handleClose = () => goBack();

  const handleButton = () => window.open('https://e-archiwum.viviraty.pl/prepareLogin.html', '_blank')?.focus();

  const DashboardBackgroundComponent = useMemo(() => (
    <LoanContext>
      <DashboardSection title={title}>
        {latestLoan ? <ActiveLoan /> : <NoActiveLoan />}
      </DashboardSection>
    </LoanContext>
  ), [latestLoan]);

  return (
    <>
      {state?.fromDashboard || pathname !== '/agreements' ? DashboardBackgroundComponent : <Settings />}
      <Modal heading passedClasses={classes.modalWrapper} passedInnerContentClasses={classes.modalInnerContent} open={modalOn} onClose={handleClose}>
        <div className={classes.heading}>
          <LockIcon />
          <SecondaryHeading>{msg('documentsModal.title')}</SecondaryHeading>
        </div>
        <div>
          {instructionSteps.map((step) => (
            <div key={step} className={classes.rowWrapper}>
              <div className={classes.bulletCircle}>{step}</div>
              <div className={classes.lineWrapper}>
                <span dangerouslySetInnerHTML={{ __html: msg(`documentsModal.steps.${step - 1}`) as string }} />
                {step === 5 && <img className={classes.instructionImg} alt="" />}
              </div>
            </div>
          ))}
        </div>
        <hr className={classes.separator} />
        <div className={classes.note}>
          <InfoIcon />
          <span>{msg('documentsModal.info')}</span>
        </div>
        <Button onClick={handleButton}>{msg('documentsModal.button')}</Button>
        <div className={classes.info}>{msg('documentsModal.note')}</div>
      </Modal>
    </>
  );
};

DocumentsModal.styleRoot = 'DocumentsModal';
export default compose<any>(withLatestLoan, injectStyles(styles))(DocumentsModal);
