import * as actions from './actions';
import { Record } from 'immutable';

export class InitialState extends Record({
  error: null as any,
  errorMessage: null as string | null,
  errorLevel: null as string | null,
}) {}

const initialState = new InitialState();

const ERROR = 'error';

export interface Action {
  type: string;
  payload?: any;
}

export default function reducer(state = initialState, action: Action): InitialState {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);
  switch (action.type) {
    case actions.SET_API_ERROR:
      return state.set(ERROR, action.payload);

    /**
     * Safe assumption that every successful fetch negates the error.
     */
    case String(action.type.match(/FETCH_(.+?)_SUCCESS/g)):
    case actions.DISMISS_ERROR: {
      return state
        .set('error', null)
        .set('errorMessage', null)
        .set('errorLevel', null);
    }
  }

  return state;
}
